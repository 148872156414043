import React, {useRef} from "react";
import {connect} from "react-redux";
import {Formik, Form as FormikForm, Form} from "formik";
import {Button, Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import "react-dates/initialize";
import {FormattedMessage} from "react-intl";
import MaterialIcon from "../elements/MaterialIcon";
import EmployeeGeneral from "./employee/EmployeeGeneral";
import EmployeeEducations from "./employee/EmployeeEducations";
import EmployeeExperiences from "./employee/EmployeeExperiences";
import EmployeeAbilities from "./employee/EmployeeAbilities";
import EmployeeLanguages from "./employee/EmployeeLanguages";
import EmployeeDataResolver from "../../helpers/EmployeeDataResolver";
import EmployeeClientDropdowns from "./EmployeeClientDropdowns";
import {
	createEmployeeAction,
	updateEmployeeAction,
} from "../../actions/employeesActions";
import {
	createResumeAction,
	updateResumeAction,
} from "../../actions/resumesActions";
import * as Yup from "yup";
import cogoToast from "cogo-toast";

import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import EmployeeCourses from "./employee/EmployeeCourses";
import EmployeeLinks from "./employee/EmployeeLinks";
import {useHistory} from "react-router-dom";

const EmployeeForm = (props) => {
	const {
		employee,
		id,
		component,
		showEmployee,
		showClient,
		fromOther,
		showProfile,
	} = props;
	const history = useHistory();

	// Check if the form is called from one of resume creation pages or from employee edit/create page
	const componentIsEmployee = () => {
		return (
			component === "EmployeeEditPage" ||
			component === "EmployeeCreatePage"
		);
	};
	console.log(component, "component fdsfds")
	// console.log("URL", history);
	let validationSchema;
	if (
		component === "EmployeeResumesCreatePage" ||
		component === "ResumeCreatePage"
	) {
		// console.log("Client IS  required here");

		validationSchema = Yup.object().shape({
			abilities: Yup.array().default([]),
			birthday: Yup.date().required("Required!"),
			country: Yup.string().required("Required!"),
			// .matches(/^[a-zA-Z]*$/, "Only letters alowed"),
			educations: Yup.array().default([]),
			courses: Yup.array().default([]),
			// email: Yup.string().required("Required!"),
			email: Yup.string("")
				.email("Enter a valid email")
				.required("Email is required")
				.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Email is not correct"),
			employee_level: Yup.object().required("Required!"),

			// employee_code: Yup.date().required("Required!"),
			first_name: Yup.string()
				.required("Required!")
				.matches(/^[a-zA-Z\s\-]*$/, "Only letters allowed")
				.matches(/^\S+$/, "Name must not contain spaces"),
			last_name: Yup.string()
				.required("Required!")
				.matches(/^[a-zA-Z\s\-]*$/, "Only letters allowed")
				.matches(/^\S+$/, "Name must not contain spaces"),
			job_title: Yup.string()
				.required("Required!")
				.matches(/^[a-zA-Z\s\-]*$/, "Only letters allowed"),
			// tariff: Yup.string().required("Required!"),
			experiences: Yup.array().default([]),
			languages: Yup.array().default([]),
			// level_get: Yup.object().required("Required!"),
			phone: Yup.string()
				.required("Required!")
				.matches(
					/^[0-9*#+]+$/,
					"Only numbers and special characters allowed"
				)
				.max(16, "Maximum number of characters exceeded "),

			// summary: Yup.string().required("Required!"),
			links: Yup.array().default([]),
			client: Yup.object().required("Required!"),
			// address: Yup.string().required("Required!"),
			// birth_place: Yup.string().required("Required!")
		});
	} else {
		// console.log("Client IS NOT required here");
		validationSchema = Yup.object().shape({
			abilities: Yup.array().default([]),
			// level: Yup.string().required("Required!"),
			birthday: Yup.date().required("Required!"),
			country: Yup.string().required("Required!"),
			// .matches(/^[a-zA-Z]*$/, "Only letters alowed"),
			educations: Yup.array().default([]),
			courses: Yup.array().default([]),
			email: Yup.string("")
				.email("Enter a valid email")
				.required("Email is required")
				.matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Email is not correct"),
			employee_level: Yup.object().required("Required!"),
			first_name: Yup.string()
				.required("Required!")
				.matches(/^[a-zA-Z\s\-]*$/, "Only letters allowed")
				.matches(/^\S+$/, "Name must not contain spaces"),
			last_name: Yup.string()
				.required("Required!")
				.matches(/^[a-zA-Z\s\-]*$/, "Only letters allowed")
				.matches(/^\S+$/, "Name must not contain spaces"),
			job_title: Yup.string()
				.required("Required!")
				.matches(/[a-zA-Z\s\-]*$/, "Only letters allowed"),
			// tariff: Yup.string().required("Required!"),
			experiences: Yup.array().default([]),
			languages: Yup.array().default([]),
			phone: Yup.string()
				.required("Required!")
				.matches(
					/^[0-9*#+]+$/,
					"Only numbers and special characters allowed"
				)
				.max(16, "Maximum number of characters exceeded "),
			// summary: Yup.string().required("Required!"),
			// address: Yup.string().required("Required!"),
			links: Yup.array().default([]),
			// birth_place: Yup.string().required("Required!")
			// status: Yup.string().required("Required!")
			employee_code: Yup.string().required("Required!"),
		});
	}
	const Langs = useRef(null);
	const Skills = useRef(null);

	return (
		<Formik
			onSubmit={(values, formikActions) => {
				// Find the component where we are and submit the form
				// console.log("VALUES", values);
				var datesError = [];
				var error;
				const educations = values.educations;
				const experiences = values.experiences;
				const courses = values.courses;
				const links = values.links;
				const status = values.status;
				const status_range = values.status_range;
				var setErr = false;
				const {setSubmitting} = formikActions;

				if (values.birthday) {
					const birth = values.birthday;
					const currentDate = moment(new Date()).format("YYYY-MM-DD");
					const status = moment(birth).isAfter(currentDate);
					datesError.push(status);
					if (status) {
						setSubmitting(false);
						cogoToast.error(
							<div style={{width: "200px"}}>
								You can't select birthday bigger than current
								date
							</div>,
							{position: "top-right"}
						);
					}
				}

				// Status date range
				if (component === "ClientResumesCreatePage") {
					if (!status_range) {
						setErr = true;
						setSubmitting(false);
						cogoToast.error(
							<div style={{width: "200px"}}>
								Start Date Is Required!
							</div>,
							{position: "top-right"}
						);
					} else {
						if (status === 'hired' && moment(status_range?.start_date).isAfter(status_range?.end_date)) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Start Date Must Be Before End Date
								</div>,
								{position: "top-right"}
							);
						}
					}
				}

				if (educations && educations.length > 0) {
					educations.forEach((education, index) => {
						const startDate = education.start_date;
						const endDate = education.end_date;
						const currentDate = moment(new Date()).format(
							"YYYY-MM-DD"
						);
						const status = moment(startDate).isAfter(endDate);
						const statusCurrentStartDate =
							moment(startDate).isAfter(currentDate);
						const statusCurrentEndDate =
							moment(endDate).isAfter(currentDate);
						datesError.push(status);
						if (status && education.present === false) {
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Start Date Less Than End Date Education
									{index + 1}
								</div>,
								{position: "top-right"}
							);
						}
						if (!startDate) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Start Date Of Education{index + 1} is
									Required
								</div>,
								{position: "top-right"}
							);
						}
						if (!endDate && education.present === false) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									End Date Of Education{index + 1} is Required
								</div>,
								{position: "top-right"}
							);
						}

						// if (statusCurrentStartDate) {
						// 	setErr = true;
						// 	setSubmitting(false);
						// 	cogoToast.error(
						// 		<div style={{ width: "200px" }}>
						// 			Start Date bigger than current date in
						// 			Education {index + 1}
						// 		</div>,
						// 		{ position: "top-right" }
						// 	);
						// }
						// if (
						// 	statusCurrentEndDate &&
						// 	education.present === false
						// ) {
						// 	setErr = true;
						// 	setSubmitting(false);
						// 	cogoToast.error(
						// 		<div style={{ width: "200px" }}>
						// 			End Date bigger than current date in
						// 			Education {index + 1}
						// 		</div>,
						// 		{ position: "top-right" }
						// 	);
						// }
					});
				} else {
					values.educations = [];
				}
				if (experiences && experiences.length > 0) {
					experiences.forEach((experience, index) => {
						const currentDate = moment(new Date()).format(
							"YYYY-MM-DD"
						);
						const startDate = experience.start_date;
						const endDate = experience.end_date;
						const status = moment(startDate).isAfter(endDate);
						const statusCurrentStartDate =
							moment(startDate).isAfter(currentDate);
						const statusCurrentEndDate =
							moment(endDate).isAfter(currentDate);
						datesError.push(status);
						if (!experience.location) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Location Of Experience{index + 1} is
									Required
								</div>,
								{position: "top-right"}
							);
						}
						if (experience.present === false && !endDate) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									End Date Of Experience{index + 1} is
									Required
								</div>,
								{position: "top-right"}
							);
						}
						if (!startDate) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Start Date Of Experience{index + 1} is
									Required
								</div>,
								{position: "top-right"}
							);
						}
						if (status && experience.present === false) {
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Start Date Less Than End Date Experience
									{index + 1}
								</div>,
								{position: "top-right"}
							);
						}
						// if (statusCurrentStartDate) {
						// 	setErr = true;
						// 	setSubmitting(false);
						// 	cogoToast.error(
						// 		<div style={{ width: "200px" }}>
						// 			Start Date bigger than current date in
						// 			Experience {index + 1}
						// 		</div>,
						// 		{ position: "top-right" }
						// 	);
						// }
						// if (
						// 	statusCurrentEndDate &&
						// 	experience.present === false
						// ) {
						// 	setErr = true;
						// 	setSubmitting(false);
						// 	cogoToast.error(
						// 		<div style={{ width: "200px" }}>
						// 			End Date bigger than current date in
						// 			Experience {index + 1}
						// 		</div>,
						// 		{ position: "top-right" }
						// 	);
						// }
					});
				} else {
					values.experiences = [];
				}

				if (courses && courses.length > 0) {
					courses.forEach((course, index) => {
						const currentDate = moment(new Date()).format(
							"YYYY-MM-DD"
						);
						const startDate = course.start_date;
						const endDate = course.end_date;
						const status = moment(startDate).isAfter(endDate);
						const statusCurrentStartDate =
							moment(startDate).isAfter(currentDate);
						const statusCurrentEndDate =
							moment(endDate).isAfter(currentDate);
						datesError.push(status);
						if (status && course.present === false) {
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Start Date Less Than End Date Course
									{index + 1}
								</div>,
								{position: "top-right"}
							);
						}

						if (!startDate) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Start Date Of Course{index + 1} is Required
								</div>,
								{position: "top-right"}
							);
						}
						if (!endDate && course.present === false) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									End Date Of Course{index + 1} is Required
								</div>,
								{position: "top-right"}
							);
						}

						// if (statusCurrentStartDate) {
						// 	setErr = true;
						// 	setSubmitting(false);
						// 	cogoToast.error(
						// 		<div style={{ width: "200px" }}>
						// 			Start Date bigger than current date in
						// 			Course {index + 1}
						// 		</div>,
						// 		{ position: "top-right" }
						// 	);
						// }
						// if (statusCurrentEndDate && course.present === false) {
						// 	setErr = true;
						// 	setSubmitting(false);
						// 	cogoToast.error(
						// 		<div style={{ width: "200px" }}>
						// 			End Date bigger than current date in Course
						// 			{index + 1}
						// 		</div>,
						// 		{ position: "top-right" }
						// 	);
						// }
					});
				} else {
					values.courses = [];
				}

				if (!values.abilities) {
					values.abilities = [];
				}
				if (!values.languages) {
					values.languages = [];
				}
				if (links && links.length > 0) {
					links.forEach((lin, index) => {
						const url = lin.link;
						if (!url.includes("http") && !url.includes(".com")) {
							setErr = true;
							setSubmitting(false);
							cogoToast.error(
								<div style={{width: "200px"}}>
									Enter Valid URL
									{index + 1}
								</div>,
								{position: "top-right"}
							);
						}
					});
				} else {
					values.links = [];
				}
				if (datesError.length === 0) {
					datesError.push(false);
				}

				error = datesError.some((el) => el);
				// check Langues
				if (values.languages.length) {
					values.languages.forEach((lang, index) => {
						if (lang.language == null || lang.level == null) {
							setErr = true;
							setSubmitting(false);
							Langs.current.scrollIntoView({
								block: "center",
								behavior: "smooth",
							});
							cogoToast.error(
								<div style={{width: "200px"}}>
									Please Enter Language {index + 1}
								</div>,
								{position: "top-right"}
							);
						}
					});
				}
				// check skills
				if (values.abilities.length) {
					values.abilities.forEach((el, index) => {
						if (!el.area || !el.skill || !el.level) {
							setErr = true;
							setSubmitting(false);
							Skills.current.scrollIntoView({
								block: "center",
								behavior: "smooth",
							});
							cogoToast.error(
								<div style={{width: "200px"}}>
									Please Enter Skills {index + 1}
								</div>,
								{position: "top-right"}
							);
						}
					});
				}
				//validation Resume image
				//
				// if (!values.profile_image) {
				// 	setErr = true;
				// 	setSubmitting(false);
				// 	cogoToast.error(`Resume image required`, {
				// 		position: "top-right",
				// 	});
				// }

				if (!setErr && !datesError.includes(true)) {
					delete values?.client?.resumes;
					return EmployeeDataResolver.resolveSubmit(
						component,
						values,
						id,
						props,
						formikActions,
						fromOther,
						error
					);
				}
			}}
			initialValues={{...employee, employee_shown: !!employee?.id}}
			enableReinitialize
			validationSchema={validationSchema}
		>
			{(formProps) => {
				// console.log("FORM PROPS", formProps);

				return (
					<div className="inputs-wrapper employee-form">
						{formProps.isSubmitting && (
							<div className="loading-overlay">
								<h5>Loading...</h5>
							</div>
						)}

						<FormikForm>
							{/* Show client or employee dropdowns if we are creating a resume */}
							{(showClient || showEmployee) && (
								<EmployeeClientDropdowns
										{...formProps}
									showEmployee={showEmployee}
									showClient={showClient}
									fromOther={fromOther}
								/>
							)}

							{/* Show "Employee Data" label if we are creating a resume */}
							{formProps.values.employee_shown &&
							!componentIsEmployee() && (
								<Row className="employee-data-header">
									<Col>
										<h3>
											<FormattedMessage id="labels.employee_data"/>
										</h3>
									</Col>
								</Row>
							)}

							{/* Display employee data forms if we have chosen employee or if we are in employee edit/create page */}
							{(formProps.values.employee_shown ||
								componentIsEmployee()) && (
								<>
									<EmployeeGeneral
										{...formProps}
										component={component}
									/>
									<Row>
										<Col className="col-lg-6 col-12">
											<EmployeeEducations
												{...formProps}
											/>

											<EmployeeExperiences
												{...formProps}
											/>

											<EmployeeCourses {...formProps} />
										</Col>

										<Col className="col-lg-6 col-12">
											<EmployeeLinks {...formProps} />
											<div ref={Skills}>
												<EmployeeAbilities
													{...formProps}
												/>
											</div>

											<div ref={Langs}>
												<EmployeeLanguages
													{...formProps}
												/>
											</div>
										</Col>
									</Row>
								</>
							)}

							<div className="submit-wrapper">
								<Button
									type="submit"
									variant="success save"
									disabled={
										formProps.isSubmitting ||
										(!formProps.values.employee_shown &&
											component !== "EmployeeCreatePage")
									}
								>
									<MaterialIcon icon="save"/>
									{componentIsEmployee() && (
										<FormattedMessage id="buttons.save_employee"/>
									)}
									{!componentIsEmployee() && (
										<FormattedMessage id="buttons.save_resume"/>
									)}
								</Button>
							</div>
						</FormikForm>
					</div>
				);
			}}
		</Formik>
	);
};

EmployeeForm.propTypes = {
	employee: PropTypes.shape({}).isRequired,
	id: PropTypes.number.isRequired,
	component: PropTypes.string.isRequired,
	showEmployee: PropTypes.bool.isRequired,
	showClient: PropTypes.bool.isRequired,
	fromOther: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
	createEmployeeAction,
	updateEmployeeAction,
	createResumeAction,
	updateResumeAction,
})(EmployeeForm);
