import cogoToast from "cogo-toast"
import history from "../routes/history"
import CountriesService from "../services/CountriesService"
import { countries } from "./types"
import getTransformedFilters from "../helpers/filterTransformer"
import HandleError from "../services/HandleError"
import Axios from "axios"

const countriesService = new CountriesService(this)

export const getCountriesAction = ({ pageSize, sorted, filters, page }) => async dispatch => {
	const query = `per_page=${pageSize}&${getTransformedFilters(filters)}`
	dispatch({ type: countries.GET_COUNTRIES_STARTED })
	try {
		const response = await countriesService.index(page, query)
		dispatch({ type: countries.GET_COUNTRIES_SUCCESS, payload: { ...response.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: countries.GET_COUNTRIES_ERROR })
	}
}

export const showCountryAction = (id, handleFinish) => async dispatch => {
	dispatch({ type: countries.SHOW_COUNTRIES_STARTED })
	try {
		const response = await countriesService.show(id)
		dispatch({ type: countries.SHOW_COUNTRIES_SUCCESS, payload: { ...response.data.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: countries.SHOW_COUNTRIES_ERROR })
	} finally {
		handleFinish()
	}
}

export const createCountryAction = (values, setSubmitting) => async dispatch => {

	dispatch({ type: countries.CREATE_COUNTRIES_STARTED })

	try {
		const response = await countriesService.store(values, false)

		dispatch({ type: countries.CREATE_COUNTRIES_SUCCESS, payload: response.data })
		history.push("/countries")
	} catch (err) {
		dispatch({ type: countries.CREATE_COUNTRIES_ERROR })

		HandleError(err)
	}finally {
		setSubmitting(false)
	}
}

export const updateCountryAction = (id, values, setSubmitting, setErrors) => async dispatch => {
	dispatch({ type: countries.UPDATE_COUNTRIES_STARTED })
	try {
		const response = await countriesService.update(id, values)
		dispatch({ type: countries.UPDATE_COUNTRIES_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Country saved successfully.", { position: "top-center" })
		history.push("/countries")
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: countries.UPDATE_COUNTRIES_ERROR })
	} finally {
		setSubmitting(false)
	}
}

export const deleteCountryAction = (id, handleFinish) => async dispatch => {

	dispatch({ type: countries.DELETE_COUNTRIES_STARTED })
	try {
		await countriesService.delete(id)
		handleFinish()
		dispatch({ type: countries.DELETE_COUNTRIES_SUCCESS, payload: { id } })
		cogoToast.success("Country deleted successfully.", { position: "top-center" })
	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: countries.DELETE_COUNTRIES_ERROR })
	}
}

export const searchCountries = (inputValue) => async () => {
	try {
		const response = await countriesService.search(inputValue || " ")
		return response.data.map((item) => ({
			id: item.id,
			name: item.name,
		}))
	} catch (error) {
		HandleError(error)
	}
}


export const FilterCountries = (inputValue) => async dispatch => {
	dispatch({ type: countries.GET_COUNTRIES_STARTED })
	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/countries?term=${inputValue}`)
		dispatch({ type: countries.GET_COUNTRIES_SUCCESS, payload: { ...response} })
	} catch (error) {
		HandleError(error);
		dispatch({ type: countries.GET_COUNTRIES_ERROR })
	}
}
