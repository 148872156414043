/**
 * 
 * @param {array} filters should an array of objects with key:value  
 * @returns {string} string of filters query prameter
 */
export default function getTransformedFilters(filters){
	let filtered = ""
	if (filtered) {
			filters.forEach(element => {
			filtered += `${element.id}=${element.value}&`
		})
	}

	return filtered
}
