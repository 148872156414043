import { dashboard } from "../actions/types"

const defaultDashboardData = {
	data: {
		result: []
	},
	redirectUrl: null,
	isLoading: true,
	hasError: false,
	error: null,
	isShowing: false,
	isLoadingActions: false,
	isSavingPermissions: false,
	isRedirecting: false,
	isExporting: false,
	isDeleting: false,
	isRestoring:false,
	isValidating:false,
	filters: [],
	meta: {
		pagination: {
			total: 0,
			count: 0,
			per_page: 15,
			current_page: 1,
			total_pages: 1,
		}
	}
}

const dashboardReducer = (state = defaultDashboardData, action = []) => {
	switch (action.type) {
		case dashboard.GET_RESULT_STARTED:
			return { ...state, isLoading: true }

		case dashboard.GET_RESULT_SUCCESS:
			if (action.payload)
				return {
					...state,
					data: { result: { ...action.payload.data.data } },
					isLoading: false
				}
			return state

		case dashboard.GET_RESULT_ERROR:
			return { ...state, isLoading: false, hasError: true }

		default:
			return state
	}
}


export default dashboardReducer
