import React, { Component } from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { FormattedMessage } from "react-intl"
import IndustryForm from "../../components/forms/IndustryForm"
import MaterialIcon from "../../components/elements/MaterialIcon"

class IndustryCreatePage extends Component {
	render() {
		return (
			<div className="clients-create-page private-page">
				<div className="top-actions-row">
					<Link to="/industries" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /> <FormattedMessage id="buttons.back_to_industry" /> </Link>
				</div>
				<Card className="clients-card fadeInLoadUp">
					<Card.Body>
						<IndustryForm />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

IndustryCreatePage.propTypes = {
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(IndustryCreatePage)
