import cogoToast from "cogo-toast"
import history from "../routes/history"
import IndustriesService from "../services/IndustriesService"
import { industries } from "./types"
import getTransformedFilters from "../helpers/filterTransformer"
import HandleError from "../services/HandleError"
import Axios from "axios"

const industriesService = new IndustriesService(this)

export const getIndustriesAction = ({ pageSize, sorted, filters, page }) => async dispatch => {
	const query = `per_page=${pageSize}&${getTransformedFilters(filters)}`
	dispatch({ type: industries.GET_INDUSTRIES_STARTED })
	try {
		const response = await industriesService.index(page, query)
		dispatch({ type: industries.GET_INDUSTRIES_SUCCESS, payload: { ...response.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: industries.GET_INDUSTRIES_ERROR })
	}
}

export const showIndustryAction = (id, handleFinish) => async dispatch => {
	dispatch({ type: industries.SHOW_INDUSTRIES_STARTED })
	try {
		const response = await industriesService.show(id)
		dispatch({ type: industries.SHOW_INDUSTRIES_SUCCESS, payload: { ...response.data.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: industries.SHOW_INDUSTRIES_ERROR })
	} finally {
		handleFinish()
	}
}

export const createIndustryAction = (values, setSubmitting) => async dispatch => {

	dispatch({ type: industries.CREATE_INDUSTRIES_STARTED })

	try {
		const response = await industriesService.store(values, false)

		dispatch({ type: industries.CREATE_INDUSTRIES_SUCCESS, payload: response.data })
		history.push("/industries")
	} catch (err) {
		dispatch({ type: industries.CREATE_INDUSTRIES_ERROR })

		HandleError(err)
	}finally {
		setSubmitting(false)
	}
}

export const updateIndustryAction = (id, values, setSubmitting, setErrors) => async dispatch => {
	dispatch({ type: industries.UPDATE_INDUSTRIES_STARTED })
	try {
		const response = await industriesService.update(id, values)
		dispatch({ type: industries.UPDATE_INDUSTRIES_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Industry saved successfully.", { position: "top-center" })
		history.push("/industries")
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: industries.UPDATE_INDUSTRIES_ERROR })
	} finally {
		setSubmitting(false)
	}
}

export const deleteIndustryAction = (id, handleFinish) => async dispatch => {

	dispatch({ type: industries.DELETE_INDUSTRIES_STARTED })
	try {
		await industriesService.delete(id)
		handleFinish()
		dispatch({ type: industries.DELETE_INDUSTRIES_SUCCESS, payload: { id } })
		cogoToast.success("Industry deleted successfully.", { position: "top-center" })
	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: industries.DELETE_INDUSTRIES_ERROR })
	}
}

export const searchIndustries = (inputValue) => async () => {
	try {
		const response = await industriesService.search(inputValue || " ")
		return response.data.map((item) => ({
			id: item.id,
			name: item.name,
		}))
	} catch (error) {
		HandleError(error)
	}
}


export const FilterIndustries = (inputValue) => async dispatch => {
	dispatch({ type: industries.GET_INDUSTRIES_STARTED })
	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/industries?term=${inputValue}`)
		dispatch({ type: industries.GET_INDUSTRIES_SUCCESS, payload: { ...response} })
	} catch (error) {
		HandleError(error);
		dispatch({ type: industries.GET_INDUSTRIES_ERROR })
	}
}
