import React, { Component } from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {FormattedMessage} from "react-intl"
import {Card} from "react-bootstrap"
import PropTypes from "prop-types"
import EmployeeForm from "../../components/forms/EmployeeForm"
import MaterialIcon from "../../components/elements/MaterialIcon"
import {showEmployeeAction} from "../../actions/employeesActions"

class EmployeeResumesCreatePage extends Component {

	state = {
		itemEmployeesIndex: -1
	}

	componentDidMount() {

		const { match, history } = this.props

		if (!match.params.id)
			history.push("/employees")

		this.props.showEmployeeAction(match.params.id, this.handleFinish)
	}

	handleFinish = () => {
		const { employees, match, history } = this.props
		const itemEmployeesIndex = employees.data.findIndex(item => (item.id === parseInt(match.params.id, 10)))

		if (itemEmployeesIndex === -1)
			history.push("/employees")

		this.setState({ itemEmployeesIndex })
	}

	render() {
		const { itemEmployeesIndex } = this.state
		const { employees } = this.props
		const employeeData = itemEmployeesIndex !== -1 ? employees.data[itemEmployeesIndex] : null

		return (
			<div className="client-edit-page private-page">
				<div className="top-actions-row">
					<Link to={`/employees/${employeeData?.id}/resumes`} className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /><FormattedMessage id="buttons.back_to_contract_resume" /></Link>
				</div>
				<Card className="client-card fadeInLoadUp">
					<Card.Body>
						<EmployeeForm employee={employeeData} id={employeeData?.id} component="EmployeeResumesCreatePage" showEmployee={false} showClient fromOther={employeeData} />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

EmployeeResumesCreatePage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	showEmployeeAction: PropTypes.func.isRequired,
	employees: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array
	}).isRequired,
}

const mapStateToProps = (state) => ({
	employees: state.employees,
})

export default connect(mapStateToProps, { showEmployeeAction })(EmployeeResumesCreatePage)
