import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { showResumeAction } from "../../actions/resumesActions";
import MaterialIcon from "../../components/elements/MaterialIcon";
import EmployeeForm from "../../components/forms/EmployeeForm";

class ResumeEditPage extends Component {
	state = {
		itemResumesIndex: -1,
		module: "",
		parentId: null,
	};

	componentDidMount() {
		const { match, history, location } = this.props;
		if (location?.pathname.includes('/clients/resumes')) {
			this.setState({ module: "clients" })
		} else if (location?.pathname.includes('/employees/resumes')) {
			this.setState({ module: "contracts" })
		}
		if (!match.params.id) history.push("/resumes");

		this.props.showResumeAction(match.params.id, this.handleFinish);
	}
	handleFinish = () => {
		const { module } = this.state;

		const { resumes, match, history, employees } = this.props;
		console.log(match.params.id, "module leu")
		const itemResumesIndex = resumes.data.findIndex(
			(item) => item.id === parseInt(match.params.id, 10)
		);
		if (itemResumesIndex === -1) history.push("/resumes");

		this.setState({ itemResumesIndex });
	};


	render() {

		const { itemResumesIndex, module } = this.state;
		const { resumes } = this.props;
		const resumeData =
			itemResumesIndex !== -1 ? resumes.data[itemResumesIndex] : null;
		const dataMain = {
			status: resumeData?.status,
			tariff_employee_monthly: resumeData?.tariff_employee_monthly,
			tariff_employee_hourly: resumeData?.tariff_employee_hourly,
			tariff_client_monthly: resumeData?.tariff_client_monthly,
			tariff_client_hourly: resumeData?.tariff_client_hourly,
		}
		const dataFormatted = {...resumeData?.data_formatted, ...dataMain}
		return (
			<div className="client-edit-page private-page">
				<div className="top-actions-row">
					<Link
						to={module === "clients" ? `/clients` : `/employees`}
						className="btn-primary btn-roundet btn-dropshadow btn btn-sm"
					>
						<MaterialIcon icon="arrow_back" />
						{module === "clients" && <FormattedMessage id="buttons.back_to_client" />}
						{module === "contracts" && <FormattedMessage id="buttons.back_to_contract" />}
					</Link>
				</div>
				<Card className="client-card fadeInLoadUp">
					<Card.Body>
						<EmployeeForm
							CurrentNav={this.props.location.state}
							employee={dataFormatted}
							id={resumeData?.data_formatted?.id}
							component="ResumeEditPage"
							showEmployee
							showClient
							fromOther={resumeData}
						/>
					</Card.Body>
				</Card>
			</div>
		);
	}
}
ResumeEditPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
		}),
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func,
	}).isRequired,
	showResumeAction: PropTypes.func.isRequired,
	resumes: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array,
	}).isRequired,
};
const mapStateToProps = (state) => ({
	resumes: state.resumes,
	employees: state.employees,
});
export default connect(mapStateToProps, { showResumeAction })(ResumeEditPage);
