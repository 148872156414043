import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import {connect} from "react-redux"
import Axios from "axios"
import HandleError from "../../services/HandleError"

const DashboardTable = ({headerText, areaId, areaName}) => {

	const [dataExamp, setDataExamp] = useState([]);
	const [isLoading, setLoading] = useState(false);

	// useEffect(() => {
	//     setLoading(true)
	//     const data = async () => {
	//         await Axios.post(`${process.env.REACT_APP_API_URL}/areas/top/skills` , {area_name:areaName}).then(res => {
	//         	console.log(res.data, "la")
	//           setDataExamp(res.data);
	//             setLoading(false);
	//         }).catch(err =>  {
	//             HandleError(err);
	//             setLoading(false);
	//         } )
	//     }
	//     data();
	// },[areaName])

	const columns = [
		{
			Header: "",
			width: 20,
			filterable: false,
			accessor: "id",
			Cell: cellProps => <div
				className={`allells ${areaName === "Programming Languages" && `ellipse${cellProps.index}` || areaName === "SAP" && `ellired${cellProps.index}` || areaName === "Frameworks" && `elliblue${cellProps.index}` || areaName === "Libraries" && `elligreen${cellProps.index}`}`}></div> || ""
		},
		{Header: "", sortable: false, filterable: false, accessor: "name", Cell: cellProps => cellProps.value || ""},
		{
			Header: "Nr. of employees",
			sortable: false,
			width: 147,
			filterable: false,
			accessor: "employees_count",
			Cell: cellProps => cellProps.value || ""
		},
		{
			Header: "% employees",
			sortable: false,
			filterable: false,
			accessor: "employees_percentage",
			Cell: cellProps => `%${cellProps.value}` || ""
		},
		{
			Header: "Growth",
			width: 90,
			sortable: false,
			filterable: false,
			accessor: "growth",
			Cell: cellProps => `${cellProps.value > 0 ? "+" : ""}${cellProps.value}` || ""
		},
	]


	return (
		<div className="table-style">
			<h6>{headerText}</h6>
			<ReactTable
				data={dataExamp}
				loading={isLoading}
				columns={columns}
				defaultPageSize={10}
				// // filterable
				// manual // for handle pagination
				// showPageSizeOptions={false}
				// // sortable={false}
				resizable={false}
				className={`striped-highlight  unique-style-table
                 ${areaName === "Programming Languages" && 'table-gray' || areaName === "SAP" && 'table-red' || areaName === "Frameworks" && 'table-blue' || areaName === "Libraries" && 'table-green'}`}
				// onPageChange={handlePaginateFetch}
			/>
		</div>
	)
}
export default DashboardTable