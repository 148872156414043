import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import { connect } from "react-redux"
import { getCountriesAction, deleteCountryAction, FilterCountries } from "../../actions/countriesActions"
import CountriesTableActionsCell from "../elements/CountriesTableActionsCell"
import DeleteModal from "../modals/DeleteModal"
import { FilterField } from "../../helpers/GeneralFillter"
import Axios from "axios"
import HandleError from "../../services/HandleError"

const CountriesTable = ({ countries, getCountriesAction, deleteCountryAction, FilterCountries}) => {
	let index = -1
	let pageNr = countries?.data?.length ? countries.meta.pagination.current_page - 1 : 0
	const totalPages = countries?.data?.length ? countries.meta.pagination.total_pages : 1
	const [showDelete, setShowDeleteModal] = useState(-1)
	const [counterPages, setCounterPages] = useState(100)

	const columns = [
		{ Header: "ID", filterable: false, accessor: "id", Cell: cellProps => cellProps.value || "" },
		{ Header: "Name", filterable: true, Filter:FilterField, accessor: "name", Cell: cellProps => cellProps.value || "" },
		{
			Header: () => <div className="header-actions">Actions</div>,
			filterable: false,
			Cell: cellProps => <CountriesTableActionsCell
				cellProps={cellProps}
				setShowDeleteModal={setShowDeleteModal}
			/>,
		}
	]
	useEffect(()=> {
		pageNr = totalPages - 1
		const filters = countries?.filters
		getCountriesAction({ pageSize: counterPages, sorted: null, filters, page: 1  })

		if(!countries?.data.length) {
			const filters = countries?.filters
			getCountriesAction({ pageSize: counterPages, sorted: null, filters, page: 1  })
		}
	}, [counterPages])
	const handleCloseDelete = () => {
		setShowDeleteModal(-1)
	}

	const handlePaginateFetch = (page) => {
		const filters = countries?.filters
		getCountriesAction({ pageSize: counterPages, sorted: null, filters, page: page + 1 });
	}

	const handleDelete = async () => {
		const { id } = countries.data[index]
		await deleteCountryAction(id, handleCloseDelete)
	
			const filters = countries?.filters
			await getCountriesAction({ pageSize: counterPages, sorted: null, filters, page: pageNr + 1 });

	}

	if (showDelete >= 0) {
		index = showDelete
	}

	const onRowClick = (state, rowInfo, column, instance) => {
		if(rowInfo) {
			setCounterPages(rowInfo.pageSize)
			return {
			}
			} else {
				return {}
			}	
	}	

	// filter data by serverside
	const FilterData = async (column, value) => {
		if(column.length){
			
			FilterCountries(column[0].value); // search by name
		}else{
			FilterCountries("")
		}
	}
	console.log("countries.data", countries?.data);
	return (
		<div className="table-style">
			<ReactTable
				data={countries?.data}
				loading={countries?.isLoading}
				columns={columns}
				defaultPageSize={15}
				pages={totalPages}
				page={pageNr}
				manual // for handle pagination
				filterable
				onFilteredChange={FilterData} 
				// defaultFilterMethod={(filter,row) => {
				// 	if(row[filter.id].toLowerCase().includes(filter.value.toLowerCase())){
				// 		return row
				// 	}
				// }}					
				showPageSizeOptions={true}
				pageSizeOptions= {[5, 10, 15, 20, 25, 50, 100]}
				sortable={false}
				className="striped-highlight remove-id unique-filter-table"
				onPageChange={handlePaginateFetch}
				getTdProps= {onRowClick}
			/>

			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={countries?.data[index] ? `Country ${countries?.data[index].name}` : ""}
				handleDelete={handleDelete}
				isDeleting={countries?.isDeleting}
			/>
		</div>
	)
}

CountriesTable.propTypes = {
	getCountriesAction: PropTypes.func.isRequired,
	deleteCountryAction: PropTypes.func.isRequired,
	countries: PropTypes.shape({
		data: PropTypes.array,
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number
			})
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool
	}).isRequired
}

const mapStateToProps = (state) => ({
	countries: state.countries
})

export default connect(mapStateToProps, { getCountriesAction, deleteCountryAction , FilterCountries })(CountriesTable)

