import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { deleteResumeAction } from "../../actions/resumesActions";
import { getEmployeeResumesAction } from "../../actions/employeeResumesActions";
import EmployeeResumesTableActionsCell from "../elements/EmployeeResumesTableActionsCell";
import getStaticUrl from "../../helpers/api";
import DeleteModal from "../modals/DeleteModal";
import { onRowClick } from "../../helpers/ViewPdf";
import moment from "moment";
import PdfModal from "../modals/PdfModal";
import { useEffect } from "react";
import { FilterField, GeneralFilter } from "../../helpers/GeneralFillter";
import { useParams } from "react-router-dom";

const EmployeeResumesTable = ({
	employee_resumes,
	getEmployeeResumesAction,
	deleteResumeAction,
	SearchRow,
}) => {
	const [ShowPdf, setShowPdf] = useState({
		status: false,
		rowId: "",
		Type: "resumes",
	});
	const [Filter, setFillter] = useState(null);
	const [counterPages, setCounterPages] = useState(10);

	useEffect(() => {
		// fillter data if user type in general filter input
		GeneralFilter(SearchRow, employee_resumes, setFillter);
	}, [SearchRow]);

	let index = -1;
	let pageNr = employee_resumes.data.length
		? employee_resumes.meta.pagination.current_page - 1
		: 0;
	let totalPages = employee_resumes.data.length
		? employee_resumes.meta.pagination.total_pages
		: 1;
	const params = useParams();
	let employeeId = params.id;

	useEffect(() => {
		pageNr = totalPages - 1;
		const { filters } = employee_resumes;
		getEmployeeResumesAction({
			employeeId: employeeId,
			page: 1,
			pageSize: counterPages,
			filters,
		});
		if (!employee_resumes.data.length) {
			const { filters } = employee_resumes;
			getEmployeeResumesAction({
				employeeId: employeeId,
				page: 1,
				pageSize: counterPages,
				filters,
			});
		}
	}, [counterPages]);

	const [showDelete, setShowDeleteModal] = useState(-1);
	const intl = useIntl();
	const columns = [
		{
			Header: "ID",
			filterable: true,
			Filter: FilterField,
			accessor: "id",
			Cell: (cellProps) => cellProps.value || "",
		},
		{
			Header: "Clients",
			filterable: true,
			Filter: FilterField,
			accessor: "client",
			filterMethod: (filter, row) => {
				if (
					row[filter.id].full_name
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				) {
					return row;
				}
			},
			Cell: (cellProps) => `${cellProps.value.full_name}` || "",
		},
		{
			Header: "Created at",
			filterable: true,
			Filter: FilterField,
			accessor: "created_at",
			Cell: (cellProps) =>
				moment(cellProps.value).format("YYYY-MM-DD") || "",
		},
		{
			Header: "Actions",
			filterable: false,
			Cell: (cellProps) => (
				<EmployeeResumesTableActionsCell
					Route={`${
						window.location.pathname.includes("/Waw")
							? "/resumes"
							: "/employees/resumes"
					}`}
					cellProps={cellProps}
					setShowDeleteModal={setShowDeleteModal}
					employeePage="employees"
				/>
			),
		},
	];

	const handleCloseDelete = () => {
		setShowDeleteModal(-1);
	};

	const handlePaginateFetch = (page) => {
		const { filters } = employee_resumes;
		getEmployeeResumesAction({
			employeeId: employeeId,
			page: page + 1,
			pageSize: counterPages,
			filters,
		});
	};

	const handleDelete = async () => {
		const { id } = employee_resumes.data[index];
		await deleteResumeAction(id, handleCloseDelete);
		const { filters } = employee_resumes;
		await getEmployeeResumesAction({
			employeeId: employeeId,
			pageSize: 10,
			sorted: null,
			filters,
			page: pageNr + 1,
		});
	};

	if (showDelete >= 0) {
		index = showDelete;
	}
	return (
		<div className="table-style">
			{ShowPdf.status && <PdfModal status={{ ShowPdf, setShowPdf }} />}
			<ReactTable
				data={Filter ? Filter : employee_resumes.data}
				loading={employee_resumes.isLoading}
				columns={columns}
				defaultPageSize={10}
				pages={totalPages}
				filterable
				manual
				// defaultFilterMethod={(filter, row) => {
				// 	if (
				// 		row[filter.id]
				// 			.toLowerCase()
				// 			.includes(filter.value.toLowerCase())
				// 	) {
				// 		return row;
				// 	}
				// }}
				showPageSizeOptions={true}
				page={pageNr}
				sortable={false}
				className="striped-highlight em-ta unique-filter-table"
				onPageChange={handlePaginateFetch}
				getTdProps={(state, rowInfo, column, instance) =>
					onRowClick(
						state,
						rowInfo,
						column,
						instance,
						setShowPdf,
						ShowPdf,
						setCounterPages
					)
				}
			/>
			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={
					employee_resumes.data[index]
						? `Resume of ${employee_resumes.data[index].client.full_name}`
						: ""
				}
				handleDelete={handleDelete}
				isDeleting={employee_resumes.isDeleting}
			/>
		</div>
	);
};

EmployeeResumesTable.propTypes = {
	getEmployeeResumesAction: PropTypes.func.isRequired,
	deleteResumeAction: PropTypes.func.isRequired,
	employee_resumes: PropTypes.shape({
		data: PropTypes.array,
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number,
			}),
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool,
	}).isRequired,
};

const mapStateToProps = (state) => ({
	employee_resumes: state.employee_resumes,
});

export default connect(mapStateToProps, {
	getEmployeeResumesAction,
	deleteResumeAction,
})(EmployeeResumesTable);
