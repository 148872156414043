// import React, { Component } from "react"
// import { connect } from "react-redux"
// import { Card } from "react-bootstrap"
// import { Link } from "react-router-dom/cjs/react-router-dom"
// import { FormattedMessage } from "react-intl"
// import MaterialIcon from "../../components/elements/MaterialIcon"
// import EmployeeForm from "../../components/forms/EmployeeForm"
//
// class ResumeCreatePage extends Component {
// 	render() {
// 		return (
// 			<div className="clients-create-page private-page">
// 				<div className="top-actions-row">
// 					<Link to="/resumes" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /> <FormattedMessage id="buttons.back_to_resume" /> </Link>
// 				</div>
// 				<Card className="employees-card fadeInLoadUp">
// 					<Card.Body>
// 						<EmployeeForm component="ResumeCreatePage" showEmployee showClient />
// 					</Card.Body>
// 				</Card>
// 			</div>
// 		)
// 	}
// }
//
// ResumeCreatePage.propTypes = {
// }
//
// const mapStateToProps = (state) => ({})
//
// export default connect(mapStateToProps, {})(ResumeCreatePage)

import React, { Component } from "react"
import { connect } from "react-redux"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { FormattedMessage } from "react-intl"
import MaterialIcon from "../../components/elements/MaterialIcon"
import EmployeeForm1 from "../../components/forms/EmployeeForm"

class ResumeCreatePage extends Component {
	render() {
		return (
			<div className="clients-create-page private-page">
				<div className="top-actions-row">
					<Link to="/resumes" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /> <FormattedMessage id="buttons.back_to_resume" /> </Link>
				</div>
				<Card className="clients-card fadeInLoadUp">
					<Card.Body>
						<EmployeeForm1 component="EmployeeCreatePage" showEmployee={false} showClient={false} />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

ResumeCreatePage.propTypes = {
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(ResumeCreatePage)

