import { countries } from "../actions/types"

const defaultCountriesData = {
	data: [],
	redirectUrl: null,
	isLoading: true,
	hasError: false,
	error: null,
	isShowing: false,
	isLoadingActions: false,
	isSavingPermissions: false,
	isRedirecting: false,
	isExporting: false,
	isDeleting: false,
	isRestoring:false,
	isValidating:false,
	filters: [],
	meta: {
		pagination: {
			total: 0,
			count: 0,
			per_page: 15,
			current_page: 1,
			total_pages: 1,
		}
	}
}

const countriesReducer = (state = defaultCountriesData, action = []) => {
	switch (action.type) {

		case countries.GET_COUNTRIES_STARTED:
			return { ...state, isLoading: true }

		case countries.GET_COUNTRIES_SUCCESS:
			if (action.payload)
				return { ...state, ...action.payload, isLoading: false }
			return state

		case countries.GET_COUNTRIES_ERROR:
			return { ...state, isLoading: false, hasError: true }

		case countries.SHOW_COUNTRIES_STARTED:
			return { ...state, isShowing: true }

		case countries.SHOW_COUNTRIES_SUCCESS:
			if (!action.payload.id)
				return state
			if (state.data.find(item => (item.id === action.payload.id)))
				return { ...state, isShowing: false, data: state.data.map(item => (item.id === action.payload.id ? action.payload : item)) }
			return { ...state, data: [...state.data, action.payload], isShowing: false }

		case countries.SHOW_COUNTRIES_ERROR:
			return { ...state, isShowing: false, hasError: true }

		case countries.CREATE_COUNTRIES_STARTED:
			return { ...state, isSaving: true }

		case countries.CREATE_COUNTRIES_SUCCESS:
			if (action.payload)
				return {
					...state,
					data: [...state.data, { ...action.payload.data }],
					isSaving: false
				}
			return state

		case countries.CREATE_COUNTRIES_ERROR:
			return { ...state, isSaving: false, hasError: true }

		case countries.DELETE_COUNTRIES_STARTED:
			return {...state, isDeleting:true}

		case countries.DELETE_COUNTRIES_SUCCESS:
			if (action.payload)
				return {...state, data: state.data.filter(item => (item.id !== action.payload.id)), isDeleting: false}
			return state

		case countries.DELETE_COUNTRIES_ERROR:
			return { ...state, isDeleting:false, hasError:true }
		default:
			return state
	}
}


export default countriesReducer
