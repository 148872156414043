import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {Button, Col, Form, Row} from "react-bootstrap"
import AsyncSelect from "react-select/async"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"
import {searchEmployees, getEmployeeAction} from "../../actions/employeesActions"
import {searchClients} from "../../actions/clientsActions"
import {fields} from "../../enum/EmployeeData"
import FormikInput from "../inputs/FormikInput"
import history from "../../routes/history"
import Select from "react-select";
import {SingleDatePicker} from "react-dates";
import moment from "moment";
import {monthAndYearDropdown} from "../../helpers/dateUtils";


const EmployeeClientDropdowns = ({
	isSubmitting,
	errors,
	touched,
	handleChange,
	values,
	setFieldValue,
	setFieldTouched,
	searchEmployees,
	searchClients,
	showEmployee,
	showClient,
	getEmployeeAction,
	fromOther
}) => {
	const [clientsOptions, setClientsOptions] = useState("")
	const [employeesOptions, setEmployeesOptions] = useState("")
	const [focused, setFocused] = useState({});
	const [monthSelected, setMonthSelected] = useState(null);
	const [yearSelected, setYearSelected] = useState(null);
	const [statusResume, setStatusResume] = useState("");

	const statusCode = [
		{label: "Proposed", value: "proposed"},
		{label: "Hired", value: "hired"},
	];

	useEffect(() => {
		const getDefaultEmployeeOptions = async () => {
			const employeesOptions = await searchEmployees("")
			setEmployeesOptions(employeesOptions)
		}
		getDefaultEmployeeOptions()

		const getDefaultClientOptions = async () => {
			const clientsOptions = await searchClients("")
			setClientsOptions(clientsOptions)
		}
		getDefaultClientOptions()

		setFieldValue("status", 'proposed')
		setStatusResume('proposed')
	}, [])

	useEffect(() => {

		setFieldValue("status", values?.status)
		setStatusResume(values?.status)
	}, [values?.status])

	useEffect(() => {
		if (fromOther?.employee) {
			setFieldValue("employee", fromOther.employee)
		}

		if (fromOther?.client) {
			setFieldValue("client", fromOther.client)
		}

	}, [fromOther?.employee, fromOther?.client])

	const setEmployeeData = async (id) => {
		const employeeDataOptions = await getEmployeeAction(id)

		Object.keys(employeeDataOptions).forEach(field => {
			setFieldValue(field, employeeDataOptions[field])
		})
	}

	const handleChangeEmployee = async (value) => {
		if (value) {
			setFieldValue("employee", value)
			setFieldValue("employee_shown", true)

			await setEmployeeData(value.id)
		} else {
			fields.forEach(field => {
				setFieldValue(field, "")
			})
			setFieldValue("employee_shown", false)

			setFieldValue("employee", "")

			setFieldTouched("employee", true)
		}
	}

	const handleChangeClient = (value) => {
		if (value !== null) {
			setFieldValue("client", value)
		} else {
			setFieldValue("client", "")
			setFieldTouched("client", true)
		}

	}

	const handleChangeStatus = (e) => {
		if (e !== null) {
			setFieldValue("status", e?.value)
			setStatusResume(e?.value)
		} else {
			setFieldValue("status", "")
			setFieldTouched("status", true)
		}
	}


	const handleStartDateChange = (value, type) => {
		if (value) {
			setFieldValue(
				`${type}[start_date]`,
				value.format("YYYY-MM-DD")
			);
			console.log("id", "value", value, "type", type);
		} else {
			setFieldValue(`${type}[start_date]`, value);
		}
	};

	const handleEndDateChange = (value, type) => {
		if (value) {
			setFieldValue(
				`${type}[end_date]`,
				value.format("YYYY-MM-DD")
			);
		} else {
			setFieldValue(`${type}[end_date]`, value);
		}
	};

	const handleStartDateChangeFocus = (focus, type) => {
		setFocused({...focused, ...{[`${type}StartDate`]: focus}});
	};

	const handleEndDateChangeFocus = (focus, type) => {
		setFocused({...focused, ...{[`${type}EndDate`]: focus}});
	};
	return (
		<>
			{!isSubmitting && <div className="inputs-wrapper form__short">
				{showEmployee && <Row>
					<Col>
						<Form.Group controlId="input_employee_source_id" className="floating-label">
							<AsyncSelect
								id="api_source_id"
								cacheOptions
								loadOptions={searchEmployees} //debounce(searchEmployees, 100)
								defaultOptions={employeesOptions}
								isDisabled={history.location.pathname.includes(`resumes/create`) ? false : true}
								// isClearable
								getOptionValue={(item) => (item.id)}
								getOptionLabel={(item) => (item.full_name)}
								onChange={(value) => handleChangeEmployee(value)}
								onBlur={() => setFieldTouched("employee", true)}
								value={values.employee}
								placeholder="Employee"
								noOptionsMessage={() => ("Type to search")}
								className={`select-input ${(!!errors.employee && touched.employee) && "is-invalid"}`}
							/>
							<Form.Control.Feedback type='invalid'>{errors.employee}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="input_employee_source_id" className="floating-label">
							<AsyncSelect
								id="api_source_id"
								cacheOptions
								loadOptions={searchEmployees} //debounce(searchEmployees, 100)
								defaultOptions={employeesOptions}
								isDisabled={history.location.pathname.includes(`resumes/create`) ? false : true}
								// isClearable
								getOptionValue={(item) => (item.id)}
								getOptionLabel={(item) => (item.employee_code)}
								onChange={(value) => handleChangeEmployee(value)}
								onBlur={() => setFieldTouched("employee", true)}
								value={values.employee}
								placeholder="Employee"
								noOptionsMessage={() => ("Type to search")}
								className={`select-input ${(!!errors.employee && touched.employee) && "is-invalid"}`}
							/>
							<Form.Control.Feedback type='invalid'>{errors.employee}</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>}

				{showClient && <Row>
					<Col>
						<Form.Group controlId="input_employee_source_id" className="floating-label">
							<AsyncSelect
								id="api_source_id"
								cacheOptions
								loadOptions={debounce(searchClients, 100)}
								defaultOptions={clientsOptions}
								isClearable
								getOptionValue={(item) => (item.id)}
								getOptionLabel={(item) => (item.full_name)}
								onChange={(value) => handleChangeClient(value)}
								onBlur={() => setFieldTouched("client", true)}
								value={values.client}
								placeholder="Client"
								noOptionsMessage={() => ("Type to search")}
								className={`select-input ${(!!errors.client && touched.client) && "is-invalid"}`}
								// isRequired={`${(!!errors.client && touched.client) && true}`}
							/>
							<div className={`select-input ${errors.client && "is-invalid"}`}></div>
							<Form.Control.Feedback type='invalid'>{errors.client}</Form.Control.Feedback>
						</Form.Group>
					</Col>
				</Row>}
				{
					showEmployee
						? <div>
							<FormikInput type="text" name="position" label="Position *" error={errors.position}
													 invalid={!!errors.position && touched.position} required/>
							<Form.Group controlId="input_employee_source_id" className="floating-label">
								<AsyncSelect
									defaultOptions={statusCode}
									getOptionValue={(item) => (item.value)}
									getOptionLabel={(item) => (item.label)}
									onChange={(value) => handleChangeStatus(value)}
									onBlur={() => setFieldTouched("status", true)}
									value={statusCode.find(
										(item) => item.value === values.status
									)}
									placeholder="Status *"
									noOptionsMessage={() => ("Type to search")}
									className={`select-input ${(!!errors.status && touched.status) && "is-invalid"}`}
								/>
								<div className={`select-input ${errors.status && "is-invalid"}`}></div>
								<Form.Control.Feedback type='invalid'>{errors.status}</Form.Control.Feedback>
							</Form.Group>
							{
								statusResume && (
									<Row>
										<Col className="m-a col-xl-6 col-lg-12 col-sm-6 col-12 calendar">
											<Form.Group
												controlId="input_birthday"
												className={`select-input ${
													!!errors?.status_range?.start_date &&
													touched?.status_range?.start_date &&
													"is-invalid"
												}`}
											>
												<SingleDatePicker
													placeholder="Select start date"
													displayFormat="DD/MM/YYYY"
													numberOfMonths={1}
													date={
														values.status_range &&
														values.status_range.start_date &&
														moment(
															values.status_range.start_date
														)
													}
													id="startDate"
													showDefaultInputIcon
													onDateChange={(e) =>
														handleStartDateChange(
															e,
															"status_range"
														)
													}
													focused={
														focused[
															`status_rangeStartDate`
															]
													}
													isOutsideRange={() => false}
													navNext={
														monthSelected === 0 &&
														yearSelected ===
														(2024 ||
															2025 ||
															2026) ? (
															<div></div>
														) : (
															""
														)
													}
													openDirection="up"
													// renderNavNextButton = {() => }
													// keepOpenOnDateSelect={() => true}
													onFocusChange={({
																						focused,
																					}) => {
														handleStartDateChangeFocus(
															focused,
															"status_range"
														);
														console.log(focused);
													}}
													renderMonthElement={({
																								 month,
																								 onMonthSelect,
																								 onYearSelect,
																							 }) => {
														setMonthSelected(
															month.month()
														);
														setYearSelected(
															month.year()
														);
														return monthAndYearDropdown(
															month,
															onMonthSelect,
															onYearSelect,
															"start_date"
														);
													}}
													required
													readOnly={true}
												/>
												<Form.Control.Feedback type="invalid">
													{errors?.status_range?.start_date}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

										<Col className="m-a col-xl-6 col-lg-12 col-sm-6 col-12 calendar">
											{statusResume == "hired" && (
												<Form.Group
													controlId="input_birthday"
													className={`select-input ${
														!!errors?.status_range?.end_date &&
														touched?.status_range?.end_date &&
														"is-invalid"
													}`}
												>
													<SingleDatePicker
														placeholder="Select end date"
														displayFormat="DD/MM/YYYY"
														numberOfMonths={1}
														date={
															values.status_range &&
															values.status_range.end_date &&
															moment(
																values.status_range.end_date
															)
														}
														id="endDate"
														showDefaultInputIcon
														onDateChange={(e) =>
															handleEndDateChange(
																e,
																"status_range"
															)
														}
														focused={
															focused[
																`status_rangeEndDate`
																]
														}
														isOutsideRange={() =>
															false
														}
														navNext={
															monthSelected ===
															0 &&
															yearSelected ===
															(2024 ||
																2025 ||
																2026) ? (
																<div></div>
															) : (
																""
															)
														}
														onFocusChange={({
																							focused,
																						}) =>
															handleEndDateChangeFocus(
																focused,
																"status_range"
															)
														}
														openDirection="up"
														renderMonthElement={({
																									 month,
																									 onMonthSelect,
																									 onYearSelect,
																								 }) => {
															setMonthSelected(
																month.month()
															);
															setYearSelected(
																month.year()
															);
															return monthAndYearDropdown(
																month,
																onMonthSelect,
																onYearSelect,
																"end_date"
															);
														}}
														required
														readOnly={true}
													/>
													<Form.Control.Feedback type="invalid">
														{errors?.status_range?.end_date}
													</Form.Control.Feedback>
												</Form.Group>
											)}

										</Col>
									</Row>
								)
							}


							<div style={{marginBottom: "0.5em"}}>Tariff of employee</div>
							<Row>
								<Col>
									<FormikInput type="number" name="tariff_employee_monthly" label="Monthly"
															 error={errors.tariff_employee_monthly}
															 invalid={!!errors.tariff_employee_monthly && touched.tariff_employee_monthly}
															 // value={values.tariff_employee_monthly}
									/>
								</Col>
								<Col>
									<FormikInput type="number" name="tariff_employee_hourly" label="Hourly"
															 error={errors.tariff_employee_hourly}
															 invalid={!!errors.tariff_employee_hourly && touched.tariff_employee_hourly}/>
								</Col>
							</Row>
							<div style={{marginBottom: "0.5em"}}>Client tariff</div>
							<Row>
								<Col>
									<FormikInput type="number" name="tariff_client_monthly" label="Monthly"
															 error={errors.tariff_client_monthly}
															 invalid={!!errors.tariff_client_monthly && touched.tariff_client_monthly}/>
								</Col>
								<Col>
									<FormikInput type="number" name="tariff_client_hourly" label="Hourly"
															 error={errors.tariff_client_hourly}
															 invalid={!!errors.tariff_client_hourly && touched.tariff_client_hourly}/>
								</Col>
							</Row>
						</div>
						: <FormikInput type="text" name="tariff" label="Tariff" error={errors.tariff} required/>
				}

			</div>}
		</>
	)
}

EmployeeClientDropdowns.propTypes = {
	values: PropTypes.shape({
		client: PropTypes.shape({}),
		employee: PropTypes.shape({}),
		tariff: PropTypes.string,
		position: PropTypes.string,
		status: PropTypes.string,
		status_range: PropTypes.array,
		tariff_employee_monthly: PropTypes.string,
		tariff_employee_hourly: PropTypes.string,
		tariff_client_monthly: PropTypes.string,
		tariff_client_hourly: PropTypes.string,
	}).isRequired,
	errors: PropTypes.shape({
		client: PropTypes.string,
		employee: PropTypes.string,
		tariff: PropTypes.string,
		position: PropTypes.string,
		status: PropTypes.string,
		status_range: PropTypes.string,
		tariff_employee_monthly: PropTypes.string,
		tariff_employee_hourly: PropTypes.string,
		tariff_client_monthly: PropTypes.string,
		tariff_client_hourly: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		client: PropTypes.bool,
		employee: PropTypes.bool,
		tariff: PropTypes.bool,
		position: PropTypes.bool,
		status: PropTypes.bool,
		status_range: PropTypes.bool,
		tariff_employee_monthly: PropTypes.bool,
		tariff_employee_hourly: PropTypes.bool,
		tariff_client_monthly: PropTypes.bool,
		tariff_client_hourly: PropTypes.bool,
	}).isRequired,
	showEmployee: PropTypes.bool.isRequired,
	showClient: PropTypes.bool.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
	searchEmployees: PropTypes.func.isRequired,
	searchClients: PropTypes.func.isRequired,
	getEmployeeAction: PropTypes.func.isRequired,
	fromOther: PropTypes.shape({
		employee: PropTypes.shape({}),
		client: PropTypes.shape({}),
	}).isRequired
}

const mapStateToProps = (state) => ({
	resumes: state.resumes
})

export default connect(mapStateToProps, {searchEmployees, searchClients, getEmployeeAction})(EmployeeClientDropdowns)

