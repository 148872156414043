import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Card } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import CountriesTable from "../../components/tables/CountriesTable"
import MaterialIcon from "../../components/elements/MaterialIcon"
import { getCountriesAction } from "../../actions/countriesActions"

class CountryPage extends Component {

	componentDidMount() {
		this.props.getCountriesAction({ pageSize: 15, sorted: null, filters: [], page: 1 })
	}

	render() {
		return (
			<div>
				<div className="top-actions-row">
					<Link to="countries/create" className="btn btn-primary btn-sm btn-roundet btn-dropshadow"><MaterialIcon icon="add" /><FormattedMessage id="buttons.new_country" /></Link>
				</div>
				<Card className="fadeInLoadUp" >
					<Card.Body>
						<CountriesTable />
					</Card.Body>
				</Card>
			</div>
		)
	}
}

CountryPage.propTypes = {
	getCountriesAction: PropTypes.func.isRequired,
	countries: PropTypes.shape({
		isLoading: PropTypes.bool,
		isExporting: PropTypes.bool,
		hasError: PropTypes.bool,
		filters: PropTypes.arrayOf(PropTypes.object),
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number.isRequired
			})
		})
	}).isRequired
}

const mapStateToProps = (state) => ({
	countries: state.countries
})

export default connect(mapStateToProps, { getCountriesAction })(CountryPage)
