import cogoToast from "cogo-toast"
import history from "../routes/history"
import EmployeesService from "../services/EmployeesServices"
import { employees } from "./types"
import getTransformedFilters from "../helpers/filterTransformer"
import HandleError from "../services/HandleError"
import { useParams } from 'react-router-dom'
import Axios from "axios"

const employeesService = new EmployeesService(this)

export const getEmployeesAction = ({ pageSize, sorted, filters, page , archive ,deleted }) => async dispatch => {
	let query;
	if(typeof archive == 'boolean'){
		query = `per_page=${pageSize}&${getTransformedFilters(filters)}${archive?"archive=true":"archive=false"}${deleted!== undefined ? deleted === false ? '&deleted=false' : '&deleted=true': ''}`;
	}else{
		query = `per_page=${pageSize}&${getTransformedFilters(filters)}${archive?"archive=true&":"archive=false&"}${deleted!== undefined ? deleted === false ? 'deleted=false' : 'deleted=true': ''}`;
	}
	dispatch({ type: employees.GET_EMPLOYEES_STARTED })
	try {
		const response = await employeesService.index(page, query);
		const data = response.data.data.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
		dispatch({ type: employees.GET_EMPLOYEES_SUCCESS, payload: { ...response.data, data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: employees.GET_EMPLOYEES_ERROR })
	}
}

export const showEmployeeAction = (id, handleFinish) => async dispatch => {
	dispatch({ type: employees.SHOW_EMPLOYEES_STARTED })
	try {
		const response = await employeesService.show(id)
		dispatch({ type: employees.SHOW_EMPLOYEES_SUCCESS, payload: { ...response.data.data } })
	} catch (err) {
		HandleError(err)
		dispatch({ type: employees.SHOW_EMPLOYEES_ERROR })
	} finally {
		handleFinish()
	}
}

export const getEmployeeAction = (id) => async () => {
	try {
		const response = await employeesService.show(id)
		return response.data.data
	} catch (err) {
		HandleError(err)
	}
}

export const createEmployeeAction = (values, setSubmitting, setErrors) => async dispatch => {

	dispatch({ type: employees.CREATE_EMPLOYEES_STARTED })

	try {
		const response = await employeesService.store(values, true)

		dispatch({ type: employees.CREATE_EMPLOYEES_SUCCESS, payload: response.data })
		history.push("/resumes", {createEmp: true})
		cogoToast.success("Resume created successfully.", { position: "top-center" })
	} catch (err) {
		dispatch({ type: employees.CREATE_EMPLOYEES_ERROR })
		setSubmitting(false)
		HandleError(err, setErrors)
	}
}

export const updateEmployeeAction = (id, values, setSubmitting, setErrors) => async dispatch => {
	dispatch({ type: employees.UPDATE_EMPLOYEES_STARTED })
	try {
		const response = await employeesService.update(id, values,true)
		dispatch({ type: employees.UPDATE_EMPLOYEES_SUCCESS, payload: { ...response.data } })
		cogoToast.success("Employee saved successfully.", { position: "top-center" })

		console.log(history);
		if(history.location.pathname.includes("/employees")) {
			 history.push("/employees")
		} else {
			history.push("/resumes")
		}
	} catch (err) {
		HandleError(err, setErrors)
		dispatch({ type: employees.UPDATE_EMPLOYEES_ERROR })
	} finally {
		setSubmitting(false)
	}
}

export const deleteEmployeeAction = (id, handleFinish) => async dispatch => {
	dispatch({ type: employees.DELETE_EMPLOYEES_STARTED })
	try {
		await employeesService.delete(id)
		handleFinish()
		dispatch({ type: employees.DELETE_EMPLOYEES_SUCCESS, payload: { id } })
		cogoToast.success("Employee deleted successfully.", { position: "top-center" })
	} catch (err) {
		handleFinish()
		HandleError(err)
		dispatch({ type: employees.DELETE_EMPLOYEES_ERROR })
	}
}

export const FilterEmployees = (inputValues) => async dispatch => {
	dispatch({ type: employees.GET_EMPLOYEES_STARTED });
	let firstName = inputValues.find(first => first.id == 'first_name');
	let lastname = inputValues.find(last => last.id == 'last_name' );
	let email = inputValues.find(email => email.id == 'email' );
	let employeelevel = inputValues.find(level => level.id == 'employee_level' );

	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/employees?first_name=${firstName?firstName.value:""}&last_name=${lastname?lastname.value:""}&email=${email?email.value:""}&employee_level=${employeelevel?employeelevel.value:""}`)
		if(history.location.pathname.includes('employees')) {
			const filterSear = response.data.filter(date => date.deleted === false)
			console.log("filterSear", filterSear);
			console.log("response", response);
		dispatch({ type: employees.GET_EMPLOYEES_SUCCESS, payload: { ...response, data: filterSear } })

		} else {
			
			dispatch({ type: employees.GET_EMPLOYEES_SUCCESS, payload: { ...response } })
		}
	} catch (error) {
		HandleError(error);
		dispatch({ type: employees.GET_EMPLOYEES_ERROR })
	}
}

export const searchEmployees = (inputValue) => async () => {
	try {
		const response = await employeesService.search(inputValue || "" , '' , null , false , false)
		return response.data.map((item) => ({
			id: item.id,
			full_name: item.full_name,
		}))
	} catch (error) {
		HandleError(error)
	}
}
