export default {
	messages: {
		will_be_deleted: " will be deleted",
		will_be_archived: "will be archived",
		will_be_Unarchived: "will be unarchiv",
	},
	buttons: {
		new_user: "New user",
		export_csv: "Export CSV",
		upload: "Upload",
		change_password: "Change password",
		close: "Close",

		new_employee: "New employee",
		back_to_employee: "Back to employees",
		edit_employee: "Edit employee",
		delete_employee: "Delete employee",
		archive_employee: "Archive employee",
		archive_resume: "Archive resume",
		unarchive_employee: "Unarchive employee",
		unarchive_resume: "Unarchive resume",
		save_employee: "Save employee",

		new_client: "New client",
		back_to_client: "Back to clients",
		edit_client: "Edit client",
		delete_client: "Delete client",
		save_client: "Save client",

		new_resume: "New resume",
		back_to_resume: "Back to Candidates",
		edit_resume: "Edit candidate",
		delete_resume: "Delete candidate",
		save_resume: "Save candidate",
		view_resumes: "View candidates",

		new_area: "New area",
		back_to_area: "Back to areas",
		edit_area: "Edit area",
		delete_area: "Delete area",
		save_area: "Save area",

		new_language: "New language",
		back_to_language: "Back to languages",
		edit_language: "Edit language",
		delete_language: "Delete language",
		save_language: "Save language",

		new_skill: "New skill",
		back_to_skill: "Back to skills",
		back_to_areas: "Back to areas",
		delete_skill: "Delete skill",
		edit_skill: "Edit skill",
		save_skill: "Save skill",

		add_education: "Add education",
		remove_education: "Remove education",

		add_experience: "Add experience",
		remove_experience: "Remove experience",

		add_course: "Add course",
		remove_course: "Remove course",

		add_link: "Add link",
		remove_link: "Remove link",

		add_ability: "Add ability",
		remove_ability: "Remove ability",

		add_language: "Add language",
		remove_language: "Remove language",

		download_resume: "Download Resume (PDF)",
		download_word: "Download as Word",

		new_country: "New country",
		back_to_country: "Back to countries",
		edit_country: "Edit country",
		delete_country: "Delete country",
		save_country: "Save country",

		new_industry: "New industry",
		back_to_industry: "Back to industries",
		edit_industry: "Edit industry",
		delete_industry: "Delete industry",
		save_industry: "Save industry",

		back_to_contract: "Back to contracts",
		back_to_contract_resume: "Back to contracts resumes",
		back_to_client_resume: "Back to clients resumes",
	},
	navigations: {
		dashboard: "Dashboard",
		clients: "Clients",
		client_resumes: "Client Resumes",
		employee_resumes: "Contracts Resumes",
		client_resume_edit: "Edit Client Employee Resume",
		audits: "Audits",
		log_out: "Log out",
		previous: "Previous",
		next: "Next",
		create_client: "Create client",
		create_client_resume: "Create client resume",
		create_employee_resume: "Create employee resume",
		edit_client: "Edit client",

		employee: "Contracts",
		resume: "Candidates",
		skill: "Skills",
		area: "Areas",
		language: "Languages",
		archive: "Archived Resumes",

		country: "Countries",
		industry: "Industries",

		contract_candidate: "Contracts to Candidates",
	},
	labels: {
		profile_settings: "Profile settings",
		create_user: "Create user",
		edit_user: "Edit user",
		edit_password: "Edit password",
		roles: "Roles",
		show_deleted: "Show deleted",
		delete: "Delete",
		archive: "Archive",
		unarchive: "Unarchive",
		close: "Close",
		educations: "Educations",
		experiences: "Experiences",
		courses: "Courses",
		links: "Links",
		abilities: "Skills",
		education: "Education",
		experience: "Experience",
		course: "Course",
		link: "Link",
		ability: "Skill",
		language: "Language",
		languages: "Languages",
		employee_data: "Employee Data",

		country: "Country",
		countries: "Countries",
		industry: "Industry",
		industries: "Industries",
	},
	app: {
		loading: "Loading...",
	},
};
