import cogoToast from "cogo-toast"
import history from "../routes/history"
import DashboardService from "../services/DashboardService"
import { dashboard } from "./types"
import getTransformedFilters from "../helpers/filterTransformer"
import HandleError from "../services/HandleError"
import Axios from "axios"

const dashboardService = new DashboardService(this)

export const getResultAction = () => async dispatch => {
	dispatch({ type: dashboard.GET_RESULT_ERROR })
	try {
		const response = await Axios.get(`${process.env.REACT_APP_API_URL}/dashboard/result`)
		dispatch({ type: dashboard.GET_RESULT_SUCCESS, payload: { ...response} })
	} catch (error) {
		HandleError(error);
		dispatch({ type: dashboard.GET_RESULT_ERROR })
	}
}