import React, {Component, useEffect} from "react"
import {Card, Col, Row} from "react-bootstrap"
import {connect, useDispatch} from "react-redux"
import DashboardTable from "../components/tables/DashboardTable"
import { getResultAction } from "../actions/dashboardAction"
import { Link } from "react-router-dom/cjs/react-router-dom"

const DashboardPage = ({dashboard}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getResultAction())
	}, [])


	return (
		<div>
			<Row>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard cursor-pointer">
						<Link style={{ color: "black", textDecoration: "none" }} to={`/resumes`}>
							<Card.Body>
								<div style={{textAlign: "center"}}>
									<div style={{fontWeight: "bold", marginBottom: "25px"}}>Resumes</div>
									<div>{dashboard.data.result.resumes}</div>
								</div>
							</Card.Body>
						</Link>
					</Card>
				</Col>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard cursor-pointer">
						<Link style={{ color: "black", textDecoration: "none" }} to={`/employees`}>
							<Card.Body>
								<div style={{textAlign: "center"}}>
									<div style={{fontWeight: "bold", marginBottom: "25px"}}>Contracts</div>
									<div>{dashboard.data.result.contracts}</div>
								</div>
							</Card.Body>
						</Link>
					</Card>
				</Col>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard cursor-pointer">
						<Link style={{ color: "black", textDecoration: "none" }} to={`/`}>
							<Card.Body>
								<div style={{textAlign: "center"}}>
									<div style={{fontWeight: "bold", marginBottom: "25px"}}>Proposed</div>
									<div>{dashboard.data.result.proposed}</div>
								</div>
							</Card.Body>
						</Link>
					</Card>
				</Col>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard cursor-pointer">
						<Link style={{ color: "black", textDecoration: "none" }} to={`/`}>
							<Card.Body>
								<div style={{textAlign: "center"}}>
									<div style={{fontWeight: "bold", marginBottom: "25px"}}>Open Requests</div>
									<div>{dashboard.data.result.openRequests}</div>
								</div>
							</Card.Body>
						</Link>
					</Card>
				</Col>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard cursor-pointer">
						<Link style={{ color: "black", textDecoration: "none" }} to={`/clients`}>
							<Card.Body>
								<div style={{textAlign: "center"}}>
									<div style={{fontWeight: "bold", marginBottom: "25px"}}>Clients</div>
									<div>{dashboard.data.result.clients}</div>
								</div>
							</Card.Body>
						</Link>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard">
						<Card.Body>
							<DashboardTable headerText="Top 10 Programming Language" areaName='Programming Languages'/>
						</Card.Body>
					</Card>
					<Card className="fadeInLoadUp unique-dashboard">
						<Card.Body>
							<DashboardTable headerText="Top 10 Frameworks" areaName="Frameworks"/>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card className="fadeInLoadUp unique-dashboard">
						<Card.Body>
							<DashboardTable headerText="Top 10 SAP" areaName="SAP"/>
						</Card.Body>
					</Card>
					<Card className="fadeInLoadUp unique-dashboard">
						<Card.Body>
							<DashboardTable headerText="Top 10 Libraries" areaName="Libraries"/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	)
}

DashboardPage.propTypes = {}

const mapStateToProps = (state) => ({
	dashboard: state.dashboard
})

export default connect(mapStateToProps, {getResultAction})(DashboardPage)
