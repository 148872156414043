import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { showIndustryAction } from "../../actions/industriesActions"
import IndustryForm from "../../components/forms/IndustryForm"
import MaterialIcon from "../../components/elements/MaterialIcon"

class IndustryEditPage extends Component {

	state = {
		itemIndustriesIndex: -1
	}

	componentDidMount() {

		const { match, history } = this.props

		if (!match.params.id)
			history.push("/industries")

		this.props.showIndustryAction(match.params.id, this.handleFinish)
	}

	handleFinish = () => {
		const { industries, match, history } = this.props
		const itemIndustriesIndex = industries.data.findIndex(item => (item.id === parseInt(match.params.id, 10)))
		if (itemIndustriesIndex === -1)
			history.push("/industries")

		this.setState({ itemIndustriesIndex })
	}

	render() {
		const { itemIndustriesIndex } = this.state
		const { industries, match } = this.props
		const industryData = itemIndustriesIndex !== -1 ? industries.data[itemIndustriesIndex] : null
		return (
			<div className="client-edit-page private-page">
				<div className="top-actions-row">
					<Link to="/industries" className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" /><FormattedMessage id="buttons.back_to_industry" /></Link>
				</div>
				<Card className="client-card fadeInLoadUp">
					<Card.Body>
						<IndustryForm industry={industryData} id={match.params.id} />
					</Card.Body>
				</Card>
			</div>
		)
	}

}
IndustryEditPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	showIndustryAction: PropTypes.func.isRequired,
	industries: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array
	}).isRequired,
}

const mapStateToProps = (state) => ({
	industries: state.industries,
})

export default connect(mapStateToProps, { showIndustryAction })(IndustryEditPage)
