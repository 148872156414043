import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { connect } from "react-redux";
import {
	getEmployeesAction,
	deleteEmployeeAction,
	searchEmployees,
	FilterEmployees,
} from "../../actions/employeesActions";
import EmployeesTableActionsCell from "../elements/EmployeesTableActionsCell";
import getStaticUrl from "../../helpers/api";
import DeleteModal from "../modals/DeleteModal";
import history from "../../routes/history";
import { FilterField, GeneralFilter } from "../../helpers/GeneralFillter";
import Axios from "axios";

const EmployeesTable = ({
	employees,
	getEmployeesAction,
	deleteEmployeeAction,
	FilterEmployees,
	SearchRow,
	deleted,
	archive,
}) => {
	let index = -1;
	const pageNr = employees.data.length
		? employees.meta.pagination.current_page - 1
		: 0;
	const totalPages = employees.data.length
		? employees.meta.pagination.total_pages
		: 1;
	const [showDelete, setShowDeleteModal] = useState(-1);
	const [Fillter, setFillter] = useState(null);
	const [counterPages, setCounterPages] = useState(100);
	useEffect(() => {
		// fillter data if user type in general filter input
		GeneralFilter(SearchRow, employees, setFillter);
	}, [SearchRow]);
	const columns = [
		{
			Header: "ID",
			width: 60,
			filterable: false,
			Filter: FilterField,
			accessor: "id",
			Cell: (cellProps) => cellProps.value || "",
		},
		{
			Header: "First name",
			width: 260,
			filterable: true,
			Filter: FilterField,
			accessor: "first_name",
			Cell: (cellProps) => cellProps.value || "",
		},
		{
			Header: "Last name",
			width: 260,
			filterable: true,
			Filter: FilterField,
			accessor: "last_name",
			Cell: (cellProps) => cellProps.value || "",
		},
		// { Header: "Job title", filterable: false, accessor: "job_title", Cell: cellProps => cellProps.value || "" },
		{
			Header: "Email",
			width: 260,
			filterable: true,
			Filter: FilterField,
			accessor: "email",
			Cell: (cellProps) => cellProps.value || "",
		},
		// { Header: "Birthday", filterable: false, accessor: "birthday", Cell: cellProps => cellProps.value || "" },
		{
			Header: "Level",
			width: 280,
			filterable: true,
			Filter: FilterField,
			filterMethod: (filter, row) => {
				if (
					row[filter.id].name
						.toLowerCase()
						.includes(filter.value.toLowerCase())
				) {
					return row;
				}
			},
			accessor: "employee_level",
			Cell: (cellProps) => cellProps.value.name || "",
		},
		// {
		// 	Header: () => <div className="header-actions uniq-action" style={{display: 'none'}}>Actions</div>,
		// 	width: 300,
		// 	filterable: false,
		// 	Cell: 'dd' ,
		// }
	];

	useEffect(() => {
		const { filters } = employees;
		getEmployeesAction({
			pageSize: counterPages,
			sorted: null,
			filters,
			page: 1,
			deleted,
			archive,
		});
	}, [counterPages]);
	const handleCloseDelete = () => {
		setShowDeleteModal(-1);
	};

	const handlePaginateFetch = (page) => {
		const { filters } = employees;
		getEmployeesAction({
			pageSize: counterPages,
			sorted: null,
			filters,
			page: page + 1,
			deleted,
			archive,
		});
	};

	const handleDelete = async () => {
		const { id } = employees.data[index];
		await deleteEmployeeAction(id, handleCloseDelete);
		const { filters } = employees;
		await getEmployeesAction({
			pageSize: counterPages,
			sorted: null,
			filters,
			page: pageNr + 1,
			deleted,
			archive,
		});
	};

	if (showDelete >= 0) {
		index = showDelete;
	}

	const onRowClick = (state, rowInfo, column, instance) => {
		if (rowInfo) {
			setCounterPages(rowInfo.pageSize);
			return {
				onClick: (e) => {
					if (
						(rowInfo && column.Header === "ID") ||
						column.Header === "First name" ||
						column.Header === "Last name" ||
						column.Header === "Job title" ||
						column.Header === "Email" ||
						column.Header === "Birthday" ||
						column.Header === "Level"
					) {
						history.push(
							`/employees/${rowInfo.original.id}/resumes`
						);
						console.log("Row Info : ", rowInfo);
					}
				},
				style: {
					cursor: rowInfo ? "pointer" : "inherit",
				},
			};
		} else {
			return {};
		}
	};
	// filter data by server
	const FilterData = async (column, value) => {
		if (column.length) {
			FilterEmployees(column);
		} else {
			const { filters } = employees;
			getEmployeesAction({
				pageSize: counterPages,
				sorted: null,
				filters,
				page: 1,
				deleted,
				archive,
			});
		}
	};
	return (
		<div className="table-style">
			<ReactTable
				data={Fillter ? Fillter : employees.data}
				loading={employees.isLoading}
				resizable={false}
				columns={columns}
				defaultPageSize={15}
				pages={totalPages}
				page={pageNr}
				manual // for handle pagination
				filterable
				// defaultFilterMethod={(filter,row) => {
				// 	if(row[filter.id].toLowerCase().includes(filter.value.toLowerCase())){
				// 		return row
				// 	}
				// }}
				onFilteredChange={FilterData}
				showPageSizeOptions={true}
				pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
				sortable={false}
				className="striped-highlight remove-id unique-filter-table search-icon-contacts"
				onPageChange={handlePaginateFetch}
				getTdProps={onRowClick}
			/>

			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={
					employees.data[index]
						? employees.data[index].first_name
						: ""
				}
				handleDelete={handleDelete}
				isDeleting={employees.isDeleting}
			/>
		</div>
	);
};

EmployeesTable.propTypes = {
	getEmployeesAction: PropTypes.func.isRequired,
	deleteEmployeeAction: PropTypes.func.isRequired,
	employees: PropTypes.shape({
		data: PropTypes.array,
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number,
			}),
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool,
	}).isRequired,
};

const mapStateToProps = (state) => ({
	employees: state.employees,
});

export default connect(mapStateToProps, {
	getEmployeesAction,
	deleteEmployeeAction,
	FilterEmployees,
})(EmployeesTable);
