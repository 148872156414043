import { Form as FormLink, withFormik } from "formik"
import PropTypes from "prop-types"
import React from "react"
import { Button, Form, Dropdown, DropdownButton } from "react-bootstrap"
import { connect } from "react-redux"
import * as Yup from "yup"
import {FormattedMessage} from "react-intl"
import { updateClientAction, createClientAction } from "../../actions/clientsActions"
import MaterialIcon from "../elements/MaterialIcon"
import getStaticUrl from "../../helpers/api";
import Select from "react-select";

const ClientForm = ({ isSubmitting, errors, touched, handleChange, values, setFieldValue, setFieldTouched, countries, industries }) => {
	const countriesCode = []
	const countriesData = countries?.data
	if (countriesData) {
		countriesData.map((item) => {
			countriesCode.push({ label: item?.name, value: item?.name })
		})
	}

	const industriesCode = []
	const industriesData = industries?.data
	if (industriesData) {
		industriesData.map((item) => {
			industriesCode.push({ label: item?.name, value: item?.name })
		})
	}

	const handleCountriesChange = (e) => {
		setFieldValue(`country`, e.value);

		if (!e) {
			setFieldTouched(`country`, true);
		}
	};

	const handleIndustriesChange = (e) => {
		setFieldValue(`industry`, e.value);

		if (!e) {
			setFieldTouched(`industry`, true);
		}
	}

	const handleChangeName = (e) => {
		setFieldValue(`full_name`, e.value);

		if (!e) {
			setFieldTouched(`full_name`, true);
		}
	}

	const handleChangeNumber = (e) => {
		const inputValue = e.target.value;

    // Limit the input to a maximum of 15 digits
    if (/^\d{0,15}$/.test(inputValue)) {
      setFieldValue(`number`, inputValue);
    }

		if (!e) {
			setFieldValue(`number`, inputValue);
		}
	}

	return (
		<FormLink className='client-form form__loading form__short private-form'>
			{isSubmitting && <div className="loading-overlay">
				<h5>Loading...</h5>
			</div>}
			<div className="inputs-wrapper">
				<Form.Group controlId="input_full_name" className="floating-label">
					<Form.Control type="text" maxlength={30} value={values.full_name} onChange={handleChange} name="full_name" isInvalid={!!errors.full_name && touched.full_name} />
					<Form.Label>Contact Name *</Form.Label>
					<Form.Control.Feedback type="invalid">{errors.full_name}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="input_number" className="floating-label">
					<Form.Control type="number" className="no-spinners" value={values.number} onChange={handleChangeNumber} name="number" isInvalid={!!errors.number && touched.number} />
					<Form.Label>Contact Number *</Form.Label>
					<Form.Control.Feedback type="invalid">{errors.number}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group
					controlId="input_country"
					className={`select-input ${
						!!errors.country &&
						touched.country &&
						"is-invalid"
					} floating-label`}
				>
					<Select
						// menuPlacement="auto"
						options={countriesCode}
						onChange={(e) => handleCountriesChange(e)}
						value={countriesCode.find(
							(item) => item.label === values.country
						)}
						placeholder="Country *"
						className="select-input"
						isInvalid={!!errors.country && touched.country}
						isRequired
					/>
					<div
						className={`select-input ${
							errors.country && touched.country && "is-invalid"
						}`}
					>
						{" "}
					</div>
					<Form.Control.Feedback type="invalid">
						{errors.country}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group
					controlId="input_industry"
					className={`select-input ${
						!!errors.industry &&
						touched.industry &&
						"is-invalid"
					} floating-label`}
				>
					<Select
						// menuPlacement="auto"
						options={industriesCode}
						onChange={(e) => handleIndustriesChange(e)}
						value={industriesCode.find(
							(item) => item.label === values.industry
						)}
						placeholder="Industry *"
						className="select-input"
						isRequired
					/>
					<div
						className={`select-input ${
							errors.industry && touched.industry && "is-invalid"
						}`}
					>
						{" "}
					</div>
					<Form.Control.Feedback type="invalid">
						{errors.industry}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="input_note" className="floating-label">
					<Form.Control type="text" as="textarea" row={4} value={values.note} onChange={handleChange} name="note" isInvalid={!!errors.note && touched.note} />
					<Form.Label>Note</Form.Label>
					<Form.Control.Feedback type="invalid">{errors.note}</Form.Control.Feedback>
				</Form.Group>
			</div>
			<div className="submit-wrapper">
				<Button type="submit" variant="success save" disabled={isSubmitting}><MaterialIcon icon="save" /> <FormattedMessage id="buttons.save_client" /> </Button>
			</div>
		</FormLink>
	)
}

const Schema = Yup.object().shape({
	full_name: Yup.string().trim().required("This field is required"),
	number: Yup.string().trim().required("This field is required"),
	country: Yup.string().trim().required("This field is required"),
	industry: Yup.string().trim().required("This field is required"),
})

const FormikApp = withFormik({
	mapPropsToValues({ full_name, number, country, industry, note, client }) {
		if (client) {
			return {
				full_name: (client.full_name).trimStart() || "",
				number: (client.number).trimStart() || "",
				country: (client.country).trimStart() || "",
				industry: (client.industry).trimStart() || "",
				note: (client.note).trimStart() || "",
			}
		}
		return {
			full_name: full_name || "",
			number: number || "",
			country: country || "",
			industry: industry || "",
			note: note || "",
		}
	},
	validationSchema: Schema,
	enableReinitialize: true,
	handleSubmit(values, { props, setSubmitting, setErrors }) {
		if (props.client) {
			props.updateClientAction(props.id, values, setSubmitting, setErrors)
		} else {
			props.createClientAction(values, setSubmitting, setErrors)
		}
	},
})(ClientForm)

ClientForm.propTypes = {
	values: PropTypes.shape({
		full_name: PropTypes.string,
		number: PropTypes.string,
		country: PropTypes.string,
		industry: PropTypes.string,
		note: PropTypes.string,
	}).isRequired,
	errors: PropTypes.shape({
		full_name: PropTypes.string,
		number: PropTypes.string,
		country: PropTypes.string,
		industry: PropTypes.string,
		note: PropTypes.string,
	}).isRequired,
	touched: PropTypes.shape({
		full_name: PropTypes.bool,
		number: PropTypes.bool,
		country: PropTypes.bool,
		industry: PropTypes.bool,
		note: PropTypes.bool
	}).isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	setFieldTouched: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	clients: state.clients,
	countries: state.countries,
	industries: state.industries,
})
export default connect(mapStateToProps, { updateClientAction, createClientAction })(FormikApp)
