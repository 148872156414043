import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import {connect} from "react-redux"
import {deleteClientAction, FilterClients, getClientsAction} from "../../actions/clientsActions"
import ClientsTableActionsCell from "../elements/ClientsTableActionsCell"
import DeleteModal from "../modals/DeleteModal"
import history from "../../routes/history"
import {FilterField, GeneralFilter} from "../../helpers/GeneralFillter"
import _ from "lodash"

const ClientsTable = ({ clients, getClientsAction, deleteClientAction, setClientsFiltersAction  , SearchRow , FilterClients}) => {

	let index = -1
	let pageNr = clients.data.length ? clients.meta.pagination.current_page - 1 : 0
	let totalPages = clients.data.length ? clients.meta.pagination.total_pages : 1
	const [showDelete, setShowDeleteModal] = useState(-1)
	const [Fillter , setFillter] = useState(null);
	const [counterPages, setCounterPages] = useState(100)
	const [dataConvert, setDataConvert] = useState([])


	useEffect(() =>{
		// fillter data if user type in general filter input 
		GeneralFilter(SearchRow ,clients ,setFillter)
	},[SearchRow])

	useEffect(() =>{
		const data = []
		if (clients?.data.length > 0) {
			clients.data.map((item) => {
				item.proposed = _.filter(item?.resumes, function (o) {
					if (o?.status === "proposed") return o
				}).length
				item.hired = _.filter(item?.resumes, function (o) {
					if (o?.status === "hired") return o
				}).length
				data.push(item)
			})
		}
		setDataConvert(data)
	},[clients.data])

	const columns = [
		{ Header: "ID", maxWidth: 300, filterable: false, accessor: "id", Cell: cellProps => cellProps.value || "" },
		{ Header: "Name", width: 350, filterable: true , Filter:FilterField, accessor: "full_name", Cell: cellProps => cellProps.value || "" },
		{ Header: "Proposed", width: 200, filterable: false , Filter:FilterField, accessor: "proposed", Cell: cellProps => cellProps.value || "" },
		{ Header: "Hired", width: 200, filterable: false , Filter:FilterField, accessor: "hired", Cell: cellProps => cellProps.value || "" },
		{
			Header:"Actions",
			filterable: false,
			Cell: cellProps => <ClientsTableActionsCell
				cellProps={cellProps}
				setShowDeleteModal={setShowDeleteModal}
			/>
		}
	]

	const handleCloseDelete = () => {
		setShowDeleteModal(-1)
	}


	useEffect(() => {
		pageNr = totalPages - 1
		if(!clients.data.length) {
			const { filters } = clients
			getClientsAction({ pageSize: counterPages, sorted: null, filters, page: 1 })
		}
	},[counterPages])
	// const handleFilterFetch = (input_filters) => {
	// 	setClientsFiltersAction({ pageSize: 15, sorted: null, filters: input_filters, page: 1 })
	// }

	const handlePaginateFetch = (page) => {
		const { filters } = clients
		getClientsAction({ pageSize: counterPages, sorted: null, filters, page: page + 1 })
	}

	const handleDelete = async () => {
		const { id } = clients.data[index]
		await deleteClientAction(id, handleCloseDelete)
		const { filters } = clients
		await getClientsAction({ pageSize: counterPages, sorted: null, filters, page: pageNr + 1 })
	}

	if (showDelete >= 0) {
		index = showDelete
	}
	const onRowClick = (state, rowInfo, column, instance) => {
		if(rowInfo) {
			setCounterPages(rowInfo.pageSize)
		return {
		
				onClick: e => {
					if(column.Header === "ID" || column.Header === "Name") {
	
						 history.push(`/clients/${rowInfo.original.id}/resumes`)
						console.log("Row Info : ", rowInfo)
					}
	
				}, 
				style: {
					'cursor': 'pointer'
				}
		}
	} else {
		return {}
	}
	}
	// filter data by serverside
	const FilterData = async (column, value) => {
		if(column.length){
			FilterClients(column[0].value); // search by name only
		}else{
			FilterClients("")
		}
	}
	return (
		<div className="table-style">
			<ReactTable
				data={Fillter?Fillter:dataConvert}
				loading={clients.isLoading}
				columns={columns}
				defaultPageSize={15}
				pages={totalPages}
				page={pageNr}
				manual // for handle pagination
				filterable
				// defaultFilterMethod={(filter,row) => {
				// 	if(row[filter.id].toLowerCase().includes(filter.value.toLowerCase())){
				// 		return row
				// 	}
				// }}
				onFilteredChange={FilterData} 
				showPageSizeOptions={true}
				pageSizeOptions= {[5, 10, 15, 20, 25, 50, 100]}
				sortable={false}
				className="striped-highlight cl-ta remove-id unique-filter-table"
				// onFilteredChange={debounce(handleFilterFetch, 400)}
				onPageChange={handlePaginateFetch}
				getTdProps= {onRowClick}
			/>

			<DeleteModal
				isShown={showDelete !== -1}
				handleClose={handleCloseDelete}
				title={clients.data[index] ? clients.data[index].full_name : ""}
				handleDelete={handleDelete}
				isDeleting={clients.isDeleting}
			/>
		</div>
	)
}

ClientsTable.propTypes = {
	getClientsAction: PropTypes.func.isRequired,
	deleteClientAction: PropTypes.func.isRequired,
	setClientsFiltersAction: PropTypes.func.isRequired,
	clients: PropTypes.shape({
		data: PropTypes.shape({
			length: PropTypes.number
		}),
		meta: PropTypes.shape({
			pagination: PropTypes.shape({
				current_page: PropTypes.number,
				total_pages: PropTypes.number
			})
		}),
		filters: PropTypes.array,
		isLoading: PropTypes.bool,
		hasError: PropTypes.bool,
		isRestoring: PropTypes.bool,
		isDeleting: PropTypes.bool
	}).isRequired
}

const mapStateToProps = (state) => ({
	clients: state.clients
})

export default connect(mapStateToProps, { getClientsAction, deleteClientAction  , FilterClients})(ClientsTable)

