import React, {useEffect, useState} from "react";
import { useSelector } from 'react-redux';
import {Form, Row, Col} from "react-bootstrap";
import AsyncSelect from "react-select/async"
import Select from "react-select"
import axios from 'axios'
import HandleError from "../../services/HandleError";
import {useDispatch} from "react-redux";
import {AdvancedSearch} from "../../actions/resumesActions";
import { getCountriesAction } from "../../actions/countriesActions"
import _ from "lodash"

const AreaSkills = ({updateSearch, pageSizeFilter}) => {
	const dispatch = useDispatch();
	const [skillsOptions, setSkillsOptions] = useState(null);
	const [areasOptions, setAreasOptions] = useState(null);
	const [langugaesOptions, setLangugaesOptions] = useState(null);
	const [countriesOptions, setCountriesOptions] = useState(null);
	const [HitSearch, setHitSearch] = useState(false); // to check if user enter search button or not to show data when user change faileds or not

	const [selectedAreas, setSelectedAreas] = useState(null);
	const [selectedSkills, setSelectedSkills] = useState(null);
	const [selectedLangs, setSelectedLangs] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState([]);


	useEffect(() => {
		// reset Hit search state if user remove all fileds data
		return setHitSearch(false)
	}, [!selectedAreas, !selectedSkills, !selectedLangs])
// get all skills when page loaded 
	// const GetAllSkills = async () => {
	//     await axios.get(`${process.env.REACT_APP_API_URL}/skills?term`).then(res => {
	//         setSkillsOptions(res.data);
	//     }).catch(error => HandleError(error));
	// }
	// get all Areas
	const getAllAreas = async () => {
		let specificAreas = ["frameworks", "programminglanguages", "sap", "libraries"]; // we wanna show thes areas only
		await axios.get(`${process.env.REACT_APP_API_URL}/areas?term`).then(res => {
			let SelectSpecifcAreas = res.data.filter(area => specificAreas.includes(area.name.toLowerCase().replaceAll(' ', '')) ?? area)
			setAreasOptions(SelectSpecifcAreas);
			let skillsArea = SelectSpecifcAreas.map(area => area.skills.filter(skill => skill.archived === false)).flat();
			setSkillsOptions(skillsArea)
		}).catch(error => HandleError(error));
	}
	// get all langugaes
	const GetAllLangugaes = async () => {
		await axios.get(`${process.env.REACT_APP_API_URL}/languages?term`).then(res => {
			setLangugaesOptions(res.data)
		}).catch(error => HandleError(error));
	}
	useEffect(() => {
		dispatch(getCountriesAction({ pageSize: 150, sorted: null, filters: [], page: 1 }))
		getAllAreas();
		// GetAllSkills();
		GetAllLangugaes();
		// setCountriesOptions([
		// 	{label: "Albania", value: "Albania"},
		// 	{label: "Kosovo", value: "Kosovo"},
		// 	{label: "Vietnam", value: "Vietnam"},
		// ])
	}, []);

	const fetchCountries = useSelector((state) => state.countries)
	useEffect(() => {
		let countriesCode = []
		const countriesData = fetchCountries?.data
		if (countriesData) {
			countriesData.map((item) => {
				countriesCode.push({ label: item?.name, value: item?.name })
			})
		}
		setCountriesOptions(countriesCode)
	}, [fetchCountries?.data])

	// lodaoptions when type on input
	const loadOptions = (inputValue, type) => {
		return new Promise((resolve, reject) => {
			// using setTimeout to emulate a call to server
			setTimeout(() => {
				resolve(filter(inputValue, type));
			}, 500);
		});
	};
	// filter when user type on input
	const filter = (inputValue, type) => {
		if (type === 'Areas') {
			return areasOptions.filter((option) =>
				option.name.toLowerCase().includes(inputValue.toLowerCase())
			);
		} else if (type === 'Skills') {
			return skillsOptions.filter((option) =>
				option.name.toLowerCase().includes(inputValue.toLowerCase())
			);
		} else if (type === 'Country') {
			return countriesOptions.filter((option) =>
				option.name.toLowerCase().includes(inputValue.toLowerCase())
			);
		} else {
			return langugaesOptions.filter((option) =>
				option.name.toLowerCase().includes(inputValue.toLowerCase())
			);
		}
	}

	// handle serach button
	const HandleSearch = async ({
																area = selectedAreas,
																skills = selectedSkills,
																languages = selectedLangs,
																countries = selectedCountry
															}) => {
		if (area || skills || languages || countries) {
			dispatch(AdvancedSearch(area, skills, languages, countries, pageSizeFilter));
			setHitSearch(true)
		} else {
			return ''
		}
	}

	const handleChangeArea = async (e) => {
		// get all skills reatlted to selected area
		setSkillsOptions(null)
		setSelectedSkills([])
		setSelectedAreas(e);
		if (e && e.id) {
			await axios.get(`${process.env.REACT_APP_API_URL}/areas/${e.id}/skills/all`).then(res => {
				setSkillsOptions(res.data);
			}).catch(error => HandleError(error))
		} else {
			getAllAreas();
			setAreasOptions(null)
			HandleSearch({area: null, skills: null});
		}
	}
	// change skills option
	const handleChangeSkills = (e) => {
		setSelectedSkills(e);
		if (!e) {
			HandleSearch({skills: null});
		}
		if (e && selectedSkills && e.length < selectedSkills.length && HitSearch) {
			HandleSearch({skills: e});
		}

	}
	// change skills option
	const handleChangeLangs = (e) => {
		if (e) {
			setSelectedLangs(e);
		} else {
			setSelectedLangs([]);
		}
		// if(e&&!e.length){
		//     HandleSearch({languages:null});
		// }
		if (!e) {
			HandleSearch({languages: []});
		}
		if (e && selectedLangs && e.length < selectedLangs.length && HitSearch) {
			HandleSearch({languages: e});
		}

	}

	const handleChangeCountries = (e) => {

		if (e) {
			setSelectedCountry(e);
		} else {
			setSelectedCountry([]);
		}
		if (!e) {
			HandleSearch({countries: []});
		}
		if (e && selectedCountry && e.length < selectedCountry.length && HitSearch) {
			HandleSearch({countries: e});
		}

	}

	// rest all data
	const RestInputs = async () => {
		setSelectedAreas(null);
		setSelectedSkills(null);
		setSelectedLangs([]);
		setSelectedCountry([]);
		// GetAllSkills();
		getAllAreas();
		dispatch(AdvancedSearch(null, null, [], [], pageSizeFilter));
		HandleSearch({area: null, skills: null, languages: [], countries: [], pageSizeFilter});
		setHitSearch(false)
	}
	return (
		<Row>
			<Col className="col-lg-3 col-sm-12 col-12">
				<Form.Group controlId="input_skill_id" className="floating-label">
					<AsyncSelect
						id="area_id"
						menuPlacement="auto"
						cacheOptions
						loadOptions={(inputValue) => loadOptions(inputValue, "Areas")}
						defaultOptions={areasOptions}
						isClearable
						getOptionValue={(item) => item.id}
						getOptionLabel={(item) => item.name}
						onChange={(e) => {
							handleChangeArea(e);
						}}
						value={selectedAreas}
						placeholder="Area"
						className="select-input"
						isOptionDisabled={(option) => option.isdisabled}
					/>
				</Form.Group>
			</Col>
			<Col className="col-lg-3 col-sm-12 col-12">
				<Form.Group>
					<AsyncSelect
						id="area_id"
						menuPlacement="auto"
						cacheOptions
						loadOptions={(inputValue) => loadOptions(inputValue, "Skills")}
						defaultOptions={skillsOptions}
						isClearable
						getOptionValue={(item) => item.id}
						getOptionLabel={(item) => item.name}
						onChange={(e) => {
							handleChangeSkills(e);
						}}
						isMulti
						value={selectedSkills}
						placeholder="Skills"
						className="select-input"
						isOptionDisabled={(option) => option.isdisabled}
					/>
				</Form.Group>
			</Col>
			<Col className="col-lg-3 col-sm-12 col-12">
				<Form.Group>
					<AsyncSelect
						id="area_id"
						menuPlacement="auto"
						cacheOptions
						loadOptions={(inputValue) => loadOptions(inputValue, "Langs")}
						defaultOptions={langugaesOptions}
						isClearable
						getOptionValue={(item) => item.id}
						getOptionLabel={(item) => item.name}
						onChange={(e) => {
							handleChangeLangs(e);
						}}
						isMulti
						value={selectedLangs}
						placeholder="Languages"
						className="select-input"
						isOptionDisabled={(option) => option.isdisabled}
					/>
				</Form.Group>
			</Col>
			<Col className="col-lg-3 col-sm-12 col-12">
				<Form.Group controlId="input_skill_id" className="floating-label">
					<Select
						// menuPlacement="auto"
						menuPlacement="auto"
						isMulti
						options={countriesOptions}
						value={selectedCountry}
						onChange={(e) => handleChangeCountries(e)}
						// value={countriesOptions?.find((item) => item.label === item.country)}
						placeholder="Country"
						className="select-input mb-4"
						isRequired
					/>
				</Form.Group>
			</Col>
			<Col className="col-lg-2 col-md-3 col-sm-6 col-6">
				<button onClick={HandleSearch} className='btn btn-primary btn-100 btn-md btn-roundet btn-dropshadow p-2'
								style={{width: '100%'}}>Search
				</button>
			</Col>
			<Col className="col-lg-2 col-md-3 col-sm-6 col-6">
				<button onClick={RestInputs} className='btn btn-danger btn-100 btn-md btn-roundet btn-dropshadow p-2'
								style={{width: '100%'}}>Clear
				</button>
			</Col>
		</Row>
	);
};

export default AreaSkills;
