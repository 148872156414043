import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { showEmployeeAction } from "../../actions/employeesActions"
import MaterialIcon from "../../components/elements/MaterialIcon"
import EmployeeForm from "../../components/forms/EmployeeForm"

class EmployeeEditPage extends Component {

	state = {
		itemEmployeesIndex: -1
	}

	componentDidMount() {
		const { match, history } = this.props
		if (!match.params.id)
			history.push("/employees")

		this.props.showEmployeeAction(match.params.id, this.handleFinish)
	}
	RedirectPath = () =>{
		
		return this.props.location.pathname.includes('/Waw') ? '/resumes' : "/employees"
	}
	handleFinish = () => {
		const { employees, match, history } = this.props
		const itemEmployeesIndex = employees.data.findIndex(item => (item.id === parseInt(match.params.id, 10)))

		if (itemEmployeesIndex === -1)
			history.push("/employees")

		this.setState({ itemEmployeesIndex })
	}

	render() {
		const { itemEmployeesIndex } = this.state
		const { employees, location } = this.props
		const employeeData = itemEmployeesIndex !== -1 ? employees.data[itemEmployeesIndex] : null

		return (
			<div className="client-edit-page private-page">
				<div className="top-actions-row">
					<Link to={this.RedirectPath} className="btn-primary btn-roundet btn-dropshadow btn btn-sm"><MaterialIcon icon="arrow_back" />
						{location.pathname.includes('/Waw')
							? <FormattedMessage id="buttons.back_to_resume" />
							: <FormattedMessage id="buttons.back_to_contract" />
						}
					</Link>
				</div>
				<Card className="client-card fadeInLoadUp">
					<Card.Body>
						<EmployeeForm CurrentNav={this.props.location.state} employee={employeeData} id={employeeData?.id} component="EmployeeEditPage" showEmployee={false} showClient={false} />
					</Card.Body>
				</Card>
			</div>
		)
	}

}
EmployeeEditPage.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string
		})
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func
	}).isRequired,
	showEmployeeAction: PropTypes.func.isRequired,
	employees: PropTypes.shape({
		isLoading: PropTypes.bool,
		data: PropTypes.array
	}).isRequired,
}
const mapStateToProps = (state) => ({
	employees: state.employees,
})
export default connect(mapStateToProps, { showEmployeeAction })(EmployeeEditPage)
